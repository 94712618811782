<template>
  <b-sidebar id="all-user-sidebar" title="All Users" shadow>
    <b-list-group v-for="(user, index) in allUsers" :key="index">
      <b-list-group-item>
        <b-button variant="light" v-on:click="filterFieldsByUser(user, index)" class="mt-auto mx-auto">
          {{ user }}

        </b-button>
          <b-collapse :id="'collapse-'+index" class="mt-2">
            <b-card>
              <p>Fields Total: {{filteredFields.length }}</p>
              <b-button variant="primary" v-on:click="emitToParent(user)" size="sm">Toggle User Fields</b-button>
            </b-card>
          </b-collapse>
    </b-list-group-item>
    </b-list-group>
  </b-sidebar>
</template>

<script>
  export default {
    name: "AllUserSidebar",

    props: {
      currentFields: {
        type: Array
      }
    },

    data () {
      return {
        allUsers: [],
        filteredFields: []
      }
    },

    methods: {
      getAllUsers () {
        let fieldsUsers = []
        this.currentFields.forEach(field => fieldsUsers.push(field.deviceId));
        this.allUsers = [... new Set(fieldsUsers)];
      },

      filterFieldsByUser (deviceId, index) {
        let id = 'collapse-'+index
        this.$root.$emit('bv::toggle::collapse', id)
        this.filteredFields = this.currentFields.filter(field =>
          field.deviceId === deviceId);
      },

      emitToParent (user) {
        this.$root.$emit('bv::toggle::collapse','sidebar-right')
        this.$root.$emit('bv::toggle::collapse', 'all-user-sidebar')
        this.$emit('childToParent', user)
      }
    },

    watch: {
      currentFields: function() {
        this.getAllUsers();
      }
    }
  }
</script>
