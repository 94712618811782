// function fetchRetry(url, options={}) {
//   return fetch(url, options).then(res => {
//     if (res.ok) {
//       return res
//     } else if (res.status === 500) {
//       console.log('response 500 retrying ....')
//       return fetchRetry(url, options)
//     }
//   })
// }

export async function getPointMarkerValue(lat, lng) {
  const response = await fetch(`https://gee-oimpdqmkkq-uc.a.run.app/api/users?lat=${lat}&lng=${lng}`, {});
  return response.json();
}

export async function getPolygonValue(polygon) {
  var requestUrl = `https://gee-oimpdqmkkq-uc.a.run.app/api/users?type=${polygon.type}&coordinates=${polygon.coordinates}`;
  let response = await fetch(requestUrl);
  return response.json();
}

export async function createUser(data) {
    const response = await fetch(`/api/user`, {
        method: 'POST',
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify({user: data})
      })
    return await response.json();
}

export async function getGee() {
    const response = await fetch(`/mapid`);
    return await response;
}
