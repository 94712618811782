<template>
  <div class="field-form-section">
    <form id="form-card" class="form field-form px-i">
      <div class="form-wrapper container-fluid field-inputs-container">
        <div class="card form-card" style="padding: 20px;">
          <b-form-group id="input-group-3" :label="$t(`crops.crop_type`)" label-for="input-2">
            <b-form-select
              id="input-3"
              v-model="cropType"
              :options="$t(`crops.types`)"
              required
            ></b-form-select>
          </b-form-group>
          <b-form-group id="input-group-2" :label="$t(`my_fields.field_area`)" label-for="input-2">
            <b-form-textarea id="textarea-plaintext" plaintext :value="fieldArea"></b-form-textarea>
          </b-form-group>

          <b-form-group id="input-group-2" :label="$t(`my_fields.field_name`)" label-for="input-2">
            <b-form-input
              id="input-2"
              :state="missingName"
              v-model="fieldName"
              @input="updateNameField"
              required
            ></b-form-input>
            <b v-if="error" class="text-danger">{{ error }}</b>
          </b-form-group>

          <b-form-group id="input-group-3" :label="$t(`irrigations.irrigation_system`)" label-for="input-3">
            <b-form-select
              id="input-3"
              v-model="irrigationSystem"
              :options="irrigationSystemTypeOptions"
              required
            ></b-form-select>
          </b-form-group>

          <b-form-group id="input-group-3" :label="$t(`pipes.pipe_size`)" label-for="input-3">
            <b-form-select
              id="input-3"
              v-model="pipeSize"
              :options="pipeSizeOptions"
              required
            ></b-form-select>
          </b-form-group>

          <b-form-group id="input-group-3" :label="$t(`irrigations.will_you_irrigate_today`)" label-for="input-3">
            <b-form-select
              id="input-3"
              v-model="irrigationState"
              :options="irrigationChoice"
            ></b-form-select>
          </b-form-group>

          <b-form-group v-if="irrigationState === 'Yes'" id="input-group-3" :label="$t(`irrigations.irrigation_duration`)" label-for="input-3">
            <b-form-select
              id="input-3"
              v-model="irrigationTime"
              :options="irrigationDurationOptions"
            ></b-form-select>
          </b-form-group>
        </div>
      </div>
    </form>
  </div>
</template>
<script>
import { mapState } from 'vuex';

export default {
  name: 'FieldForm',

  props: {
    field: { type: Object },
    error: { type: String, default: null },
  },

  data() {
    return {
      windowWidth: window.innerWidth,
      missingName: null,
      irrigationSystemTypeOptions:
        [
          {value: 'Drip', text: this.$i18n.t(`irrigations.Drip`)},
          {value: 'Bubbler', text: this.$i18n.t(`irrigations.Bubbler`)},
          {value: 'Microsprinkler', text: this.$i18n.t(`irrigations.Microsprinkler`)},
          {value: 'Solid-set impact sprinkler', text: this.$i18n.t(`irrigations.Solid-set impact sprinkler`)},
          {value: 'Center Pivot', text: this.$i18n.t(`irrigations.Center Pivot`)},
          {value: 'Linear move', text: this.$i18n.t(`irrigations.Linear move`)},
          {value: 'Hand-move sprinkler', text: this.$i18n.t(`irrigations.Hand-move sprinkler`)},
          {value: 'Boom sprayer', text: this.$i18n.t(`irrigations.Boom sprayer`)},
          {value: 'Gun', text: this.$i18n.t(`irrigations.Gun`)},
          {value: 'Surface-Basin', text: this.$i18n.t(`irrigations.Surface-Basin`)},
          {value: 'Surface-Furrow', text: this.$i18n.t(`irrigations.Surface-Furrow`)}
        ],
      pipeSizeOptions:
        [
          {value: "32 mm, 1 inch", text: this.$i18n.t(`pipes.32 mm, 1 inch`)},
          {value: "40 mm, 1.25 inch", text: this.$i18n.t(`40 mm, 1.25 inch`)},
          {value: "50 mm, 1.5 inch", text: this.$i18n.t(`50 mm, 1.5 inch`)},
          {value: "63 mm, 2 inch", text: this.$i18n.t(`pipes.63 mm, 2 inch`)},
          {value: "75 mm, 2.5 inch", text: this.$i18n.t(`75 mm, 2.5 inch`)},
          {value: "90 mm, 3 inch", text: this.$i18n.t(`pipes.90 mm, 3 inch`)},
          {value: "110 mm, 4 inch", text: this.$i18n.t(`pipes.110 mm, 4 inch`)},
          {value: "140 mm, 5 inch", text: this.$i18n.t(`pipes.140 mm, 5 inch`)},
          {value: "160 mm, 6 inch", text: this.$i18n.t(`pipes.160 mm, 6 inch`)}
        ],
      irrigationChoice:
      [
        {value: "Yes", text: this.$i18n.t(`global.yes`)},
        {value: "No", text: this.$i18n.t('global.no')}
      ],
      irrigationDurationOptions: [
        "0.25", "0.5", "1",
        "1.5", "2", "2.5",
        "3", "3.5", "4",
        "4.5", "5", "6",
        "7", "8", "9",
        "10", "11", "12"
      ]
    }
  },

  watch: {
    error: function (val) {
      if (val) {
        this.missingName = false
      }
    },
  },

  computed: {
		// Get user and fields from store
      ...mapState(['user', 'fields', 'activeField', 'draftCrop', 'draftIrrigationSystem', 'draftPipeSize', 'draftField']),

      cropType: {
        get() {
          if (this.draftField) {
            return this.draftField.cropType;
          } else {
            return this.field.cropType;
          }
        },
        set(newValue) {
          if (this.draftField) {
            this.draftField.cropType = newValue;
          } else {
            this.activeField.cropType = newValue;
          }
        }
      },

      irrigationSystem: {
        get() {
          if (this.draftField) {
            return this.draftField.irrigationSystem;
          } else {
            return this.field.irrigationSystem;
          }
        },
        set(newValue) {
          if (this.draftField) {
            this.draftField.irrigationSystem = newValue;
          } else {
            this.activeField.irrigationSystem = newValue;
          }
        }
      },

      pipeSize: {
        get() {
          if (this.draftField) {
            return this.draftField.pipeSize;
          } else {
            return this.field.pipeSize;
          }
        },
        set(newValue) {
          if (this.draftField) {
            this.draftField.pipeSize = newValue;
          } else {
            this.activeField.pipeSize = newValue;
          }
        }
      },

      fieldArea () {
        if (this.draftField) {
          return (this.draftField.fieldArea/10000).toFixed(2);
        } else {
          return (this.field.fieldArea/10000).toFixed(2);
        }
      },

      fieldName: {
        get() {
          if (this.draftField) {
            return this.draftField.name;
          } else {
            return this.field.name;
          }
        },
        set(newValue) {
          if (this.draftField) {
            this.draftField.name = newValue;
          } else {
            this.field.name = newValue;
          }
        }
      },

      irrigationState () {
        if (this.draftField) {
          return 'Yes';
        } else {
          return this.field.irrigationState;
        }
      },

      irrigationTime () {
        if (this.draftField) {
          return 0.25;
        } else {
          return this.field.irrigationTime;
        }
      }

    },

    methods: {
      updateNameField () {
        var name;
        if (this.field) {
          name = this.field.name;
        } else {
          name = this.draftField.name;
        }
        if (this.draftField) {
          if (this.fields.map(field => field.name).includes(name)) {
            this.missingName = true;
            this.error = this.$t('field.nameExists');
          } else {
            this.missingName = null;
            this.error = null;
          }
        } else {
          if (this.fields.filter(field => field.key !== this.field.key).map(field => field.name).includes(name)) {
            this.missingName = true;
            this.error = this.$t('field.nameExists');
          } else {
            this.missingName = null;
            this.error = null;
          }
        }
      }
    }
};
</script>

<style lang="scss" scoped>
@media (max-width: 768px) {
  .mobile-navbar {
    background-color: #EAEAEA !important;
    padding: 3rem 0.5rem 0.5rem 0.65rem
  }
}

.field-form-section {
  display: flex;
  flex-direction: column;

  .field-form {
    margin-top: 1rem;

    .field-inputs-container {
      display: flex;
      flex-direction: column;

      .control-container {
        display: flex;
        justify-content: center;
        margin-top:  1rem;

        .submit-button {
          padding: 0.5rem 2rem;
        }
      }
    }
  }
}
</style>
