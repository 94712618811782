<template>
  <div class="main" id="agsat-app">
    <Header :active-user="activeUser" @submitSearch="submitSearch" />

<!-- ********************** START CONTROL PANEL **************************** -->
    <div class="control-panel">
      <b-button-toolbar
        class="d-flex justify-content-between justify-content-md-start">
        <div class="d-none d-md-flex">
          <b-button variant="transparent" v-b-tooltip.hover.bottom :title="$t(`realisation.realisation`)">
            <b-icon v-b-modal.realisation-modal
              icon="info-circle-fill" scale="1.5" variant="white">
            </b-icon>
          </b-button>
          <b-button variant="transparent" v-b-tooltip.hover.bottom :title="$t(`global.user_guide`)">
            <b-icon @click="toggleIntro()"
              icon="book" scale="1.5" variant="white">
            </b-icon>
          </b-button>

          <Realisation />

          <GmailLogin
            :mobile-view-port="mobileViewport"
            :active-user="activeUser"
            :platform="platform()"
          />
        </div>

<!-- - - - - - - - - - - - - - - FIELDS BUTTONS - - - - - - - - - - - - - -  -->
        <div class="my-2" style="display: flex;">
          <b-button v-b-tooltip.hover.bottom :title="$t(`global.user_guide`)" variant="transparent" class="d-md-none d-lg-none" style="height: fit-content; margin-top: 0.3rem;">
            <b-icon
              @click="toggleIntro()"
              icon="book" scale="1.5" variant="white">
            </b-icon>
          </b-button>
          <div class="card-demo" style="height: fit-content">
            <b-button
              v-if="activeUser || mobileViewport"
              :class="mobileButton"
              id="draw-field-button"
              @click="startDrawing($event)"
              :variant="mobileViewport ? `light` : `primary`"
              pill size="md" class="mx-1 mt-1" >

              {{ $t(`dashboard.${drawButtonText}`) }}
            </b-button>
            <b-button
              id="draw-field-button"
              v-else
              variant="primary"
              pill size="md" class="mx-1 mt-1 user-guide-button d-none" >

              {{ $t(`dashboard.${drawButtonText}`) }}
            </b-button>
            <b-button
              id="save-field-button"
              variant="primary"
              pill size="md" class="mx-1 mt-1 user-guide-button-two d-none" >

              {{ $t(`global.save`)}}
            </b-button>
          </div>
          <div class="mt-1">
            <b-button
              v-if="showEditButton && activeField && !stopFetchingData" :class="mobileButton"
              @click="editField(activeField.key)"
              variant="primary" class="mx-1 my-auto mt-1 d-flex" pill>
              <b-icon icon="pencil"></b-icon>
              <p class="mb-0 button-text" style="margin-left: 0.5rem">{{ $t(`global.edit` )}}</p>
            </b-button>
          </div>
          <div class="mt-1">
            <b-button
              v-if="showSaveButton && !fetchingData" :class="mobileButton"
              v-b-modal.new-field-modal
              pill class="mx-1 my-auto mt-1 d-flex" variant="primary">
                <b-icon icon="pencil"></b-icon>
              <p class="mb-0 button-text" style="margin-left: 0.5rem"> {{ $t(`global.save`)}}</p>
            </b-button>
          </div>
          <div class="mt-1">
            <b-button
            v-if="showUpdateButton && !fetchingData" :class="mobileButton"
            @click="showEditModal(activeField.key)"
            pill class="mx-1 my-auto mt-1" variant="primary">

            <font-awesome-icon icon="save" />

              {{ $t(`global.update`)}}
            </b-button>
          </div>
          <div class="mt-1">
            <b-button
              v-if="activeField && !stopFetchingData" :class="mobileButton"
              @click="showDeleteMsg(activeField.name, activeField.key)"
              class="mx-1 my-auto mt-1 d-flex" pill variant="danger">

              <b-icon icon="trash"></b-icon>
              <p class="mb-0 button-text" style="margin-left: 0.5rem">{{ $t(`global.delete` )}}</p>
            </b-button>
          </div>
          <div class="mt-1">
            <b-button
              v-if="showSaveButton"
              :class="mobileButton" @click="clearField"
              variant="danger" pill class="mx-1 my-auto mt-1">

              <font-awesome-icon icon="broom" />

              {{ $t(`global.clear` )}}
            </b-button>
          </div>
          <div class="mt-1">
            <b-button
              v-if="showClearButton"
              :class="mobileButton" @click="clear"
              variant="danger" pill class="mx-1 my-auto mt-1">

              <font-awesome-icon icon="broom" />

              {{ $t(`global.clear` )}}
            </b-button>
          </div>
          <div class="mt-1">
            <b-button
              v-if="adminUser"
              v-b-toggle.all-user-sidebar>
              Toggle All Users Sidebar
            </b-button>
          </div>
        </div>

<!-- - - - - - - - - - -  ZOOM CONTROLS ON MOBILE VIEW - - - - - - - - - - - -->
      <div class="d-flex mt-1 d-md-none">
        <b-button-group vertical>
          <b-button
            @click="goToLocation()" size="md" class="my-1" variant="light">
            <img src="../../assets/locate-me.png"/>
          </b-button>
          <b-button
            @click="updateZoom('in')" size="md" class="my-1" variant="light">
            <b-icon icon="zoom-in" aria-hidden="true"></b-icon>
          </b-button>
          <b-button
            size="md" @click="updateZoom('out')" class="my-1" variant="light">
            <b-icon icon="zoom-out" aria-hidden="true"></b-icon>
          </b-button>
        </b-button-group>
      </div>

      </b-button-toolbar>
      <FieldIndex :currentFields="currentFields" v-on:childToParent="deleteField" v-on:updateFields="updateFields"/>
      <FieldNew :field="fieldBuilder" :current-fields="currentFields"  v-on:updateFields="updateFields"/>
      <FieldEdit :existingFields="fieldDetails" v-if="activeField" :field="activeField" v-on:updateFields="updateFields" v-on:editPolygon="editPolygon"/>
      <SocialMediaShareModal />
    </div>
<!-- ************************* END CONTROL PANEL *************************** -->

      <div>
          <div ref="polygonElement" class="polygon-element" :class="rectangleWindow"></div>

          <gmap-map
      :center="center"
      :zoom="zoom"
      ref="googleMap"
      @click="showModals($event)"
      :options='{ mapTypeControl: mapTypeControl,
        zoomControl: true, streetViewControl: false, rotateControl: true }'
      map-type-id="hybrid"
      style="position: static;">
        <div v-if="locationCenter && (this.currentFields.length === 0)">
          <gmap-marker
            :position="center"
            :clickable="true">
            <gmap-info-window
              :position="center">
              {{ $t(`global.you_are_here`)}}
            </gmap-info-window>
          </gmap-marker>
        </div>

        <gmap-info-window
          :options="infoOptions"
          :position="infoWindowPos"
          :opened="infoWinOpen"
          :content="infoContent"
          @closeclick="infoWinOpen=false">
        </gmap-info-window>
      </gmap-map>


      <CropTypeModal :clear-field="clearSelectedModalFields" @showIrrigationModal="showIrrigationModal" @endDrawing="endDrawing" @clearFields="clear" />
      <IrrigationSystemModal :clear-field="clearSelectedModalFields" @showPipeSizeModal="showPipeSizeModal" @endDrawing="endDrawing" @clearFields="clear"/>
      <PipeSizeModal :clear-field="clearSelectedModalFields" @tirggerPolygonTool="polygonTool" @endDrawing="endDrawing" @clearFields="clear"/>

      <b-modal centered id="irrigation-duration-alert" >
        <p class="my-4">{{ $t(`global.irrigation_exceeded` )}}</p>

        <template  #modal-footer="{ ok, cancel }">
          <b-button size="md" @click="cancel()">{{ $t(`global.cancel`) }}</b-button>
          <b-button size="md" variant="primary" @click="ok()">{{ $t(`global.ok`) }}</b-button>
        </template>
      </b-modal>

      <!-- <GetYourFieldIrrigationModal /> -->
      <AllUsersSidebar :currentFields="currentFields" v-on:childToParent="filterFieldByUserSelection" />

      <b-sidebar id="sidebar-right" no-header right shadow>
        <div class="custom-header" style="display: flex;">
          <h4 id="sidebar-no-header-title" style="padding: 1rem;width: 75%;">All user fields</h4>
          <b-icon style="padding: 1.5rem; cursor: url('../../hand-cursor.png')" icon="x-circle" @click="closeSidebar" class="close-sidebar"/>
        </div>
        <div class="px-3 py-2">
          <p>
            This user {{ this.selectedUserAccount }} has {{ filteredFieldsBySelection.length }} Fields
          </p>
          <div v-for="(fieldInfo, index) in filteredFieldsBySelection" :key="index" style="display: flex; flex-display: column" class="field-details">
            <div style="width: 2rem;" @click="goToField(fieldInfo)">
              <b-icon icon="eye-fill"/>
            </div>
            <div>
              Key: {{ fieldInfo.key }} <br/>
              Name: {{ fieldInfo.name }} <br/>
              Area: {{ fieldInfo.fieldArea }} <br/>
              Type: {{ fieldInfo.cropType }} <br/>
              Irrigation System: {{ fieldInfo.irrigationSystem }} <br/>
              Pipe Size: {{ fieldInfo.pipeSize }} <br/>
              <div v-for="(fieldHistory, fieldHistoryIndex) in filteredHistoryValues(fieldInfo.key)" :key="fieldHistoryIndex">
                <div>
                  Date/EtValue: {{ fieldHistoryIndex }}: {{ fieldHistory.EtValue }} <br/>
                </div>
              </div>
            </div>
          </div>
        </div>
      </b-sidebar>

      <ResultsTab :results-object="res"
        :hide-tabs="hideTabs"
        :fetching-data="fetchingData"
        :series="series"
        :options="options"
        @fetchDataChanged="(e) => { stopFetchingData = e }"
        @hideTabsChanged="(e) => { hideTabs = e }"
      />
      <div v-if="showHelpText && currentFields.length === 0"
        class="position-fixed fixed-bottom m-0 rounded-0 help-text">
        {{ $t(`dashboard.${helpText}`) }}
      </div>
    </div>

  </div>
</template>

<script>
import IrrigationSystemModal from './IrrigationSystemModal';
import PipeSizeModal from './PipeSizeModal';
import CropTypeModal from './CropTypeModal';
// import GetYourFieldIrrigationModal from './getYourFieldIrrigationModal';
import AllUsersSidebar from './AllUsersSidebar';
import ResultsTab from './ResultsTab';
import SocialMediaShareModal from './SocialMediaShareModal';
import Realisation from './Realisation';
import FieldIndex from '../Field/FieldIndex';
import FieldNew from '../Field/FieldNew';
import FieldEdit from '../Field/FieldEdit';
import Header from './Header';
import GmailLogin from './GmailLogin';
import store from '../../stores/FieldStore';
import userStore from '../../stores/UserStore';
import historyStore from '../../stores/HistoryStore';
import * as firebase from '../../firebase';
import _ from 'lodash';
import { faHandPointer } from '@fortawesome/free-solid-svg-icons';
import { getPointMarkerValue, getPolygonValue  } from '../../services/UserService';
import { Capacitor, Plugins } from "@capacitor/core";
import { MarkerClusterer } from "@googlemaps/markerclusterer";

// const { Device } = Plugins;
const { Geolocation } = Plugins;
const { ScreenCapture } = Plugins;

export default {
  name: 'Dashboardold',

  components: {
    IrrigationSystemModal,
    PipeSizeModal,
    CropTypeModal,
    // GetYourFieldIrrigationModal,
    AllUsersSidebar,
    ResultsTab,
    SocialMediaShareModal,
    FieldIndex,
    FieldNew,
    FieldEdit,
    Realisation,
    Header,
    GmailLogin
  },

  data() {
      return {
        deviceInfo: null,
        deviceId: null,
        store: store,
        handled: false,
        userStore: userStore,
        historyStore: historyStore,
        windowWidth: window.innerWidth,
        hideTabs: true,
        fetchingData: false,
        options: {
          chart: {
            id: 'vuechart-example'
          },
          xaxis: {
            categories: [1991, 1992, 1993, 1994, 1995, 1996, 1997, 1998]
          }
        },
        series: [{
          name: 'series-1',
          data: [30, 40, 45, 50, 49, 60, 70, 91]
        }],
        currentLocationCenter: null,
        infoOptions: {
          pixelOffset: {
            width: 0,
            height: -35
          },
          content: ''
        },
        irrigationRatio: 1,
        editablePolygon: null,
        currentLocationPin: false,
        detectedLocation: false,
        zoom: 11,
        center: { lat: 33.89, lng: 35.90 },
        mapTypeControl: false,
        selectedCrop: '',
        selectedPipeSize: '',
        selectedIrrigationSystemType: '',
        clearSelectedModalFields: false,
        editMode: false,
        placeMarker: null,
        infoWinOpen: false,
        centerLatitude: 0,
        finalArry: null,
        centerLongitude: 0,
        res: [],
        pipeSizeRatio: null,
        adminMarkers: [],
        irrigationSystemTypes: [
          'Drip',
          'Bubbler',
          'Microsprinkler',
          'Solid-set impact sprinkler',
          'Center Pivot',
          'Linear move',
          'Hand-move sprinkler',
          'Boom sprayer',
          'Gun',
          'Surface-Basin',
          'Surface-Furrow'
        ],
        pipeSizes:[
          "32 mm, 1 inch",
          "40 mm, 1.25 inch",
          "50 mm, 1.5 inch",
          "63 mm, 2 inch",
          "75 mm, 2.5 inch",
          "90 mm, 3 inch",
          "110 mm, 4 inch",
          "140 mm, 5 inch",
          "160 mm, 6 inch"
        ],
        meter_per_hectare: this.$t('global.meter_cube_per_hectare'),
        mm_per_day: this.$t('global.mm_per_day'),
        hectare: this.$t('global.hectare'),
        meter_cube_per_day: this.$t('global.meter_cube_per_day'),
        hour_per_day: this.$t('global.hr_per_day'),
        Kc: 0,
        Et0: 0,
        totalEt: 0,
        drawButtonText: "draw_field",
        mapPolygons: [],
        timeStamps: [],
        forecastTimeStamps: [],
        showEditButton: false,
        showSaveButton: false,
        showClearButton: false,
        showUpdateButton: false,
        activeField: null,
        fieldBuilder: {},
        result: null,
        fieldDetails: [],
        infoContent: '',
        drawingMode: false,
        adminUser: false,
        allUsers: [],
        infoWindowPos: {
          lat: 0,
          lng: 0
        },
        infoWindow: '',
        historyValues: [],
        selectedUserAccount: null,
        activeUser: null,
        currentFields: [],
        filteredFieldsBySelection: [],
        pointClick: true,
        showHelpText: true,
        helpText: "help_text_step_1",
        stopFetchingData: false,
        polyline: null,
        demoPolygon: null,
        polygonElement: null,
        activeFieldPolygon: null
      }
  },

  methods: {
    // stopFetchingData () {
    //   this.activeField = null;
    //   this.drawingMode = true;
    //   this.hideTabs = true;
    // },

      async takeScreenshot() {
          try {
              const result = await ScreenCapture.capture();
              // The result variable contains the captured image data or file URI
              console.log('Screenshot captured:', result);
          } catch (error) {
              console.error('Failed to capture screenshot:', error);
          }
      },
      drawPolygon() {
          let vmInstance = this;
        vmInstance.$refs.googleMap.$mapPromise.then((map) => {
        vmInstance.drawingManagerTool.setMap(map);

        const fieldCoords = [
            { lat: 33.911670, lng: 36.056760 },
            { lat: 33.912017, lng: 36.057752 },
            { lat: 33.911341, lng: 36.058744 },
            {lat: 33.910797, lng: 36.058036},
            {lat: 33.911670, lng: 36.056760 }
        ];
        // Construct the polygon.
        var completeField = new window.google.maps.Polygon({
            paths: fieldCoords,
            strokeOpacity: 0,
            strokeWeight: 0,
            fillOpacity: 0,
            editable: false,
            strokeColor: "#00000000",
            fillColor: "#00000000",
            saved: false
        });
        completeField.setMap(map);
            vmInstance.demoPolygon = completeField;
            vmInstance.polygonElement = vmInstance.$refs.polygonContainer.firstChild;
      });
    },

      platform () {
          return Capacitor.isNative;
      },

    toggleIntro () {
      let vmInstance = this;
      let intro = vmInstance.$intro();
      vmInstance.showHelpText = false;
      let startPos1 = { lat: 33.911670, lng: 36.056760 };
      let endPos1 =   { lat: 33.912017, lng: 36.057752 };
      let endPos2 = {lat: 33.911670, lng: 36.056760 };
      let endPos3 =   { lat: 33.911341, lng: 36.058744 };
      let endPos4 = {lat: 33.910797, lng: 36.058036};
      this.goToLocation(33.911652, 36.057618);
      var completeField;
      var polylines = [];
      var a = [];
      function DrawPolyline(vmInstance, startPos, endPos, polyline) {
        vmInstance.$refs.googleMap.$mapPromise.then((map) => {
          vmInstance.drawingManagerTool.setMap(map);

          // document.getElementsByClassName("gmnoprint")[0].children[1].children[0].click();

          const pos2 = {
            lat: startPos.lat,
            lng: startPos.lng,
          };
          if (a.length !== 0) {
            for (let i = 0; i < a.length; i++) {
              a[i].setMap(null);
            }
          }
          var marker2 = new window.google.maps.Marker({
            position: pos2,
            icon: {
              path: faHandPointer.icon[4],
              fillColor: "#ffffff",
              fillOpacity: 1,
              anchor: new window.google.maps.Point(
                faHandPointer.icon[0] / 2, // width
                faHandPointer.icon[1] /// height
              ),
              strokeWeight: 1,
              strokeColor: "#000000",
              scale: 0.035,
            },
            map: map});

          if (polyline) {
            const flightPlanCoordinates = [
              {
                lat: endPos.lat,
                lng: endPos.lng,
              },
              {
                lat: startPos.lat,
                lng: startPos.lng,
              },
            ];
            const flightPath = new window.google.maps.Polyline({
              path: flightPlanCoordinates,
              geodesic: true,
              strokeColor: "#F57F17",
              fillColor: "#526f2f",
              strokeOpacity: 1.0,
              strokeWeight: 2,
              editable: true
            });
              vmInstance.polyline =  flightPath;
            flightPath.setMap(map);
            polylines.push(flightPath);
          }

            a.push(marker2);
          new window.google.maps.event.trigger( marker2, 'click' );
          var e = {lat: startPos.lat, lng: startPos.lng}
          new window.google.maps.event.trigger(map, 'click', e);
          var e2 = {lat: endPos.lat, lng: endPos.lng};
          new window.google.maps.event.trigger(map, 'click', e2);
        });
      }
      function DrawPolygon(vmInstance) {
        vmInstance.$refs.googleMap.$mapPromise.then((map) => {
          vmInstance.drawingManagerTool.setMap(map);
          if (polylines.length !== 0) {
            for (let i = 0; i < polylines.length; i++) {
              polylines[i].setMap(null);
            }
          }
          document.getElementsByClassName("gmnoprint")[0].children[1].children[0].click();
          a[3].setMap(null);
          const fieldCoords = [
            { lat: 33.911670, lng: 36.056760 },
            { lat: 33.912017, lng: 36.057752 },
            { lat: 33.911341, lng: 36.058744 },
            {lat: 33.910797, lng: 36.058036},
            {lat: 33.911670, lng: 36.056760 }
          ];
          // Construct the polygon.
          completeField = new window.google.maps.Polygon({
            paths: fieldCoords,
            strokeOpacity: 0.8,
            strokeWeight: 2,
            fillOpacity: 0.35,
            editable: true,
            strokeColor: "#F57F17",
            fillColor: "#526f2f",
          });
          completeField.setMap(map);
        });
      }
      intro.setOptions({
        nextLabel: vmInstance.$t('guide.next'),
        prevLabel: vmInstance.$t('guide.previous'),
        steps: [
        {
          title: vmInstance.$t('guide.title_1'),
          intro: vmInstance.$t('guide.step_1')
        },
        {
          title: vmInstance.$t('guide.title_2'),
          element: document.querySelector('.gmail-login'),
          intro: vmInstance.$t('guide.step_2')
        },
        {
          title: vmInstance.$t('guide.title_3'),
          element: document.querySelector('.card-demo'),
          intro: vmInstance.$t('guide.step_3')
        },
        {
          title: vmInstance.$t('guide.title_4'),
            element: vmInstance.$refs.polygonElement,
          intro: vmInstance.$t('guide.step_4')
        },
        {
            intro: "",
            element: vmInstance.$refs.polygonElement,
            title: ""
        },
        {
            intro: "",
            element: vmInstance.$refs.polygonElement,
            title: ""
        },
        {   intro: "",
            element: vmInstance.$refs.polygonElement,
            title: ""
        },
        {   intro: "",
            element: vmInstance.$refs.polygonElement,
            title: ""
        },
        {
          title: vmInstance.$t('guide.title_5'),
          element: document.getElementsByClassName("user-guide-button-two")[0],
          intro: vmInstance.$t('guide.step_5')
        },
        {
          title: vmInstance.$t('guide.title_6'),
          element: document.getElementsByClassName("nav-item")[0],
          intro: vmInstance.$t('guide.step_6')
        },
        {
          title: vmInstance.$t('guide.title_7'),
          element: document.getElementsByClassName("nav-item")[1],
          intro: vmInstance.$t('guide.step_7')
        },
        ]
      });

      intro.start().onbeforechange(function() {
        if (intro._currentStep == "2") {
          if (typeof document.getElementsByClassName('user-guide-button')[0] !== 'undefined') {
            document.getElementsByClassName('user-guide-button')[0].classList.remove("d-none");
          }
        }
        if (intro._currentStep == "3") {
          document.getElementsByClassName('introjs-prevbutton')[0].classList.remove("d-none");
          document.getElementsByClassName('introjs-tooltip')[0].style.backgroundColor = "white";
        }
              if (intro._currentStep == "4") {
                  console.log(vmInstance.demoPolygon);
              }

        if (intro._currentStep == "8") {
          vmInstance.hideTabs = false;
          document.getElementsByClassName("fixed-bottom")[0].classList.remove("d-none");
          document.getElementsByClassName('introjs-tooltipbuttons')[0].classList.remove("d-none");
        }
      }).onchange(function() {
        if (intro._currentStep == "4") {
            document.getElementsByClassName('introjs-tooltip')[0].style.backgroundColor = "transparent";
          DrawPolyline(vmInstance, startPos1, endPos1, false);
          setTimeout(() => {
            document.getElementsByClassName("introjs-nextbutton")[0].click();
          }, 1500);
        }
        if (intro._currentStep.toString() == "5") {
          DrawPolyline(vmInstance, endPos1, endPos2, true);
          setTimeout(() => {
            document.getElementsByClassName("introjs-nextbutton")[0].click();
          }, 1500);
        }
        if (intro._currentStep == "6") {
          DrawPolyline(vmInstance, endPos3, endPos1, true);
          setTimeout(() => {
            document.getElementsByClassName("introjs-nextbutton")[0].click();
          }, 1500);
        }
        if (intro._currentStep == "7") {
            document.getElementsByClassName('introjs-tooltip')[0].style.backgroundColor = "transparent";
          DrawPolyline(vmInstance, endPos4, endPos3, true);
          setTimeout(() => {
            document.getElementsByClassName("introjs-nextbutton")[0].click();
          }, 1500);
        }
        if (intro._currentStep == "8") {
          DrawPolygon(vmInstance);
            document.getElementsByClassName('introjs-tooltip')[0].style.backgroundColor = "white";
          document.getElementsByClassName('introjs-tooltiptext')[0].style.height = "fit-content";
          if (document.getElementsByClassName('user-guide-button').length > 0) {
            document.getElementsByClassName('user-guide-button')[0].classList.add("d-none");
          }
          if (typeof document.getElementsByClassName('user-guide-button-two')[0] !== 'undefined') {
            document.getElementsByClassName('user-guide-button-two')[0].classList.remove("d-none");
          }
          vmInstance.hideTabs = false;
        }
      });

      intro.onexit(function() {
        vmInstance.showHelpText = true;
        vmInstance.hideTabs = true;
        if (document.getElementsByClassName('user-guide-button').length > 0) {
          document.getElementsByClassName('user-guide-button')[0].classList.add("d-none");
        }
        if (polylines.length !== 0) {
          for (let i = 0; i < polylines.length; i++) {
            polylines[i].setMap(null);
          }
        }
        if (a.length !== 0) {
          for (let i = 0; i < a.length; i++) {
            a[i].setMap(null);
          }
        }
        if (completeField) {
          completeField.setMap(null);
        }
        document.getElementsByClassName("gmnoprint")[0].children[0].children[0].click();
        document.getElementsByClassName("fixed-bottom")[0].classList.add("d-none");
        document.getElementsByClassName('user-guide-button-two')[0].classList.add("d-none");

        vmInstance.goToLocation();
        vmInstance.$bvModal.hide('new-field-modal');
      });
    },

    async updateFields(key = null, field = null) {
      var vmInstance = this;
      if (key) {
        this.stopFetchingData = false;
        let currentField = vmInstance.getField(key);
        vmInstance.showSaveButton = false;
        vmInstance.showUpdateButton = false;
        vmInstance.showClearButton = false;
        vmInstance.fieldBuilder = {};
        if (vmInstance.polygon) {
          vmInstance.polygon.setMap(null);
        }
        vmInstance.fetchFields();
        vmInstance.clear();
        vmInstance.activeField = field || currentField;
        vmInstance.showFieldDetails(vmInstance.activeField);
      } else {
        if (vmInstance.polygonMarker) {
          vmInstance.polygonMarker.setMap(null);
        }
        vmInstance.mapPolygons.forEach(function (polygon) {
          polygon.setMap(null);
        });
        vmInstance.fieldDetails = [];
        vmInstance.activeField = null;
        vmInstance.clear();
        vmInstance.showUpdateButton = false;
        vmInstance.fetchFields();
      }
    },

    getField (fieldId) {
      return this.currentFields.filter(field => field.key === fieldId)[0];
    },

    async getDeviceInfo() {
      if (this.mobileViewport) {
        firebase.auth.signInAnonymously()
        .then(() => {
        })
        .catch((error) => {
          error.code;
        });
      }
    },

    requestLocationPermission() {
      const requestLocation = Geolocation.requestPermissions({},
      (response, err) => {
        console.log('Location Permissision error', err);
      });
      return requestLocation;
    },

    goToField (fieldInfo) {
      this.infoWindowPos.lat = fieldInfo.markerLatitude;
      this.infoWindowPos.lng = fieldInfo.markerLongitude;
      var fieldAreaInHectare = (parseFloat(fieldInfo.fieldArea/10000)).toFixed(2);
      this.infoOptions.content = this.showInfoContent(fieldInfo.cropType, fieldInfo.name, fieldAreaInHectare);
      this.$refs.googleMap.$mapPromise.then((map) => {
        map.setCenter(new window.google.maps.LatLng(fieldInfo.markerLatitude, fieldInfo.markerLongitude));
        var vmInstance = this;
        if (vmInstance.polygonMarker) {
          vmInstance.polygonMarker.setMap(null);
        }
        vmInstance.polygonMarker = new window.google.maps.Marker({
          position: vmInstance.infoWindowPos,
          map: map
        });
      });
    },

    showIrrigationModal (croptype) {
      this.selectedCrop = croptype;
      this.$bvModal.show('irrigation-system-modal');
    },

    showEditModal(key) {
      let id = 'edit-'+key;
      if (this.activeFieldPolygon) {
          var drawnPolygon = [];
          for (var i = 0; i < this.activeFieldPolygon.getPath().getLength(); i++) {
              drawnPolygon.push(this.activeFieldPolygon.getPath().getAt(i).toUrlValue(6).split(","))
          }
          drawnPolygon.push(this.activeFieldPolygon.getPath().getAt(0).toUrlValue(6).split(","));
          var position = this.setFieldPositionFromCoordinates(drawnPolygon);
          this.activeField.position = position;
      }
      if (this.editablePolygon) {
          this.editablePolygon.setMap(null);
      }

      this.$root.$emit('bv::show::modal', id);
    },

    getPipeSizeRatio (pipeSize) {
      switch(this.pipeSizes.indexOf(pipeSize)) {
        case 0:
          this.pipeSizeRatio = 2.6;
          break;
        case 1:
          this.pipeSizeRatio = 4.0;
          break;
        case 2:
          this.pipeSizeRatio = 6.0;
          break;
        case 3:
          this.pipeSizeRatio = 11.0;
          break;
        case 4:
          this.pipeSizeRatio = 17.0;
          break;
        case 5:
          this.pipeSizeRatio = 24.0;
          break;
        case 6:
          this.pipeSizeRatio = 42.0;
          break;
        case 7:
          this.pipeSizeRatio = 66.0;
          break;
        case 8:
          this.pipeSizeRatio = 90.0;
          break;
      }
    },

    getIrrigationRatio () {
      switch(this.irrigationSystemTypes.indexOf(this.selectedIrrigationSystemType)) {
        case 0:
          this.irrigationRatio = 0.85;
          break;
        case 1:
          this.irrigationRatio = 0.8;
          break;
        case 2:
          this.irrigationRatio = 0.8;
          break;
        case 3:
          this.irrigationRatio = 0.75;
          break;
        case 4:
          this.irrigationRatio = 0.85;
          break;
        case 5:
          this.irrigationRatio = 0.8;
          break;
        case 6:
          this.irrigationRatio = 0.75;
          break;
        case 7:
          this.irrigationRatio = 0.75;
          break;
        case 8:
          this.irrigationRatio = 0.7;
          break;
        case 9:
              this.irrigationRatio = 0.6;
              break;
        case 10:
              this.irrigationRatio = 0.5;
              break;
      }
    },

    updateZoom (type) {
      if (type === 'in') {
        if (this.zoom < 20) {
          this.zoom += 1;
        }
      } else if  (type === 'out') {
        if (this.zoom > 1) {
          this.zoom -= 1;
        }
      }
    },

    async goToLocation (lat= null, lng= null) {
      this.$refs.googleMap.$mapPromise.then((map) => {
      if (lat && lng) {
        const pos = {
          lat: lat,
          lng: lng
        };
         map.setCenter(pos);
         map.setZoom(17);
      }
     });
    },

    handleLocationError(browserHasGeolocation) {
      console.log(browserHasGeolocation);
    },

    showPipeSizeModal (irrigationSystem, irrigationRatio) {
      this.selectedIrrigationSystemType = irrigationSystem;
      this.irrigationRatio = irrigationRatio;

      if (this.pointClick) {
        this.getPointIrrigation();
      } else {
        this.$bvModal.show('pipe-size-modal');
      }
    },

    showModals (clickEvent) {
      if (this.mobileViewport) {
        this.fetchingData = true;
        this.centerLatitude = clickEvent.latLng.lat();
        this.centerLongitude = clickEvent.latLng.lng();
        var point = {lat: this.centerLatitude, lng: this.centerLongitude};
        var vmInstance = this;
        vmInstance.infoWinOpen = false;
        vmInstance.infoOptions.content = null;
        vmInstance.activeField = null;

        if (this.pointMarker) {
          this.pointMarker.setMap(null);
        }

        this.$refs.googleMap.$mapPromise.then((map) => {
          vmInstance.pointMarker = new window.google.maps.Marker({
            position: point,
            map: map
          })
        });

        this.$bvModal.show('crop-type-modal');
      }
    },

    polygonTool (pipeSize) {
      this.selectedPipeSize = pipeSize;
      document.getElementsByClassName("gmnoprint")[0].children[1].children[0].click();
    },

    startDrawing (event) {
      this.clearSelectedModalFields = true;
      this.$bvModal.show('crop-type-modal');
      event.target.disabled = true;
      this.showEditButton = false;
      this.activeField = null;
      this.drawingMode = true;
      this.pointClick = false;
      this.showClearButton = true;
      this.hideTabs = true;
      this.drawButtonText = "calculate_et";
      this.helpText = "help_text_step_2";
      var vmInstance = this;
      if (this.mobileViewport) {
        this.$refs.googleMap.$mapPromise.then((map) => {
          vmInstance.drawingManagerTool.setMap(map);
        });
      } else {
        document.getElementsByClassName("gmnoprint")[0].children[1].children[0].click();
      }
    },

    filteredHistoryValues(key) {
      var result = this.historyValues.filter(entry =>  entry.key === key);
      var a = _.omit(result[0], 'key');
      return a;
    },

    closeTabs () {
      this.hideTabs = true;
    },

    closeSidebar () {
      document.getElementById("sidebar-right").style.display= "none";
    },

    endDrawing () {
      this.drawButtonText = "draw_field";
      this.drawingMode = false;
      this.pointClick = true;
      this.showHelpText = false;
      this.showClearButton = false;
      document.getElementById("draw-field-button").disabled = false;
      document.getElementsByClassName("gmnoprint")[0].children[0].children[0].click();
    },

    async fetchFields () {
      if (this.activeUser && ((this.activeUser.email === "hadijaafar@hotmail.com"))) {
        this.adminUser = true;
        this.currentFields = await this.store.fetchFields();
      } else if (this.mobileViewport) {
        this.currentFields = await this.store.fetchFieldsByDevice(this.deviceId);
      } else {
        this.currentFields = await this.store.fetchFieldsByDevice(this.activeUser.email);
      }
    },

    fetchHistoryValuesByField (fieldKey) {
      var result = this.historyStore.fetchHistoryValuesByField(fieldKey);
      return result[0];
    },

    showGetIrrigationModal () {
      if (!this.activeUser) {
        this.$bvModal.show('help-alert');
      }
    },

    clear () {
      var vmInstance = this;
      vmInstance.drawButtonText = "draw_field";
      vmInstance.selectedCrop = '';
      vmInstance.selectedPipeSize = '';
      vmInstance.selectedIrrigationSystemType = '';
      vmInstance.drawingMode = false;
      vmInstance.pointClick = true;
      vmInstance.infoWinOpen = false;
      vmInstance.clearSelectedModalFields = true;
      document.getElementById("draw-field-button").disabled = false;

      document.getElementsByClassName("gmnoprint")[0].children[0].children[0].click();
      if (vmInstance.polygon) {
        vmInstance.polygon.setMap(null);
        vmInstance.polygonMarker.setMap(null);
      }
      vmInstance.showSaveButton = false;
      vmInstance.showClearButton = false;
      vmInstance.hideTabs = true;
      vmInstance.fieldBuilder = {}
      vmInstance.$bvModal.hide('new-field-modal');
    },

    clearField () {
      this.saveDemoField();
      this.polygon.setMap(null);
      this.polygonMarker.setMap(null);
      this.fieldBuilder = {};
      this.infoWinOpen = false;
      this.hideTabs = true;
      this.showSaveButton = false;
    },

    async saveDemoField () {
      this.fieldBuilder.deviceId = localStorage.activeDevice;
      this.store.saveDemoField(this.fieldBuilder, localStorage.activeDevice);
    },

    showIrrigationSystemModal () {
      this.$bvModal.show('irrigation-system-modal');
    },


    editField (key) {
      this.infoWinOpen = false;
      this.hideTabs = true;
      this.stopFetchingData = true;
      // var vmInstance = this;
      this.showEditModal(key);
      this.fetchFields();
      this.stopFetchingData = false;
    },

    showDeleteMsg(field_name, key) {
      this.$bvModal.msgBoxConfirm(this.$t('my_fields.are_your_sure_you_want_to_delete')+field_name + "", {
        title: this.$t('my_fields.delete_your_field'),
        size: 'sm',
        buttonSize: 'sm',
        okVariant: 'danger',
        okTitle: this.$t('global.yes'),
        cancelTitle: this.$t('global.no'),
        footerClass: 'p-2',
        hideHeaderClose: false,
        centered: true
      })
      .then(value => {
        if (value) {
          this.deleteField(key)
        }
      })
    },

    deleteField (fieldKey) {
      store.deleteField(fieldKey);
      this.currentFields = this.currentFields.filter(field => field.key !== fieldKey);
      this.currentFields = _.uniqBy(this.currentFields, 'key');
      this.mapPolygons.forEach(function (polygon) {
        polygon.setMap(null);
      });
      this.activeField = null;
      this.infoWinOpen = false;
      this.closeTabs();
    },

    createMarker (place, map) {
      if (this.placeMarker) {
        this.placeMarker.setMap(null)
      }
      return this.placeMarker = new window.google.maps.Marker({
        map,
        position: place.geometry.location
      });
    },

    getPointIrrigation () {
      this.series = [{data:  []}];
      this.options = {xaxis: {categories:  []}};
      this.result = this.getData(null, this);
      this.infoWindowPos.lat = this.centerLatitude;
      this.infoWindowPos.lng = this.centerLongitude;
      this.showHelpText = false;
      this.hideTabs = false;
      this.pointClick = true;
    },

    getData (polygon, vmInstance) {
      var monthAgoDate = new Date();
      monthAgoDate.setMonth(monthAgoDate.getMonth() - 1);

      if (polygon) {
        vmInstance.pointClick = false;
      }

      // Define a constant for the timeout duration (in milliseconds)
      const timeoutDuration = 20000; // 20 seconds

      // Add a new Promise to handle the timeout scenario
      const timeoutPromise = new Promise((resolve, reject) => {
        setTimeout(() => {
          reject(new Error('Timeout occurred.'));
        }, timeoutDuration);
      });

      if (vmInstance.activeField) {
        let formatedCoordinates = this.getFormatedPolygon(vmInstance.activeField.position);
        let existingPolygon = {
          "type": "Polygon",
          "coordinates": formatedCoordinates
        };

        vmInstance.fetchingData = true;
        Promise.race([getPolygonValue(existingPolygon), timeoutPromise])
          .then(response => {
            if (!response || response.error) {
              this.infoOptions.content = vmInstance.showNoData();
              this.infoWinOpen = true;
              this.hideTabs = true;
              this.fetchingData = false;
            } else {
              this.fetchPolygonValues(response, vmInstance, vmInstance.activeField.fieldArea);
              this.fetchingData = false;
            }
          })
          .catch(error => {
            // Handle the timeout error
            this.infoOptions.content = 'Request timed out. Please check your connection';
            this.infoWinOpen = true;
            this.hideTabs = true;
            this.fetchingData = false;
            console.error(error);
          });

      } else if (vmInstance.pointClick) {
        getPointMarkerValue(vmInstance.centerLatitude, vmInstance.centerLongitude).then(response => {

          if (response.error) {
            this.infoOptions.content = vmInstance.showNoData();
            this.infoWinOpen = true;
            this.hideTabs = true;
          } else {
            this.fetchPolygonValues(response, vmInstance, null);
            this.fetchingData = false;
          }
        });

      } else if (vmInstance.fieldBuilder) {
        let formatedCoordinates = this.getFormatedPolygon(vmInstance.fieldBuilder.position);
        let newPolygon = {
          "type": "Polygon",
          "coordinates": formatedCoordinates
        };
          vmInstance.fetchingData = true;

        getPolygonValue(newPolygon).then(response => {
          if (!response || response.error) {
            this.infoOptions.content = vmInstance.showNoData();
            this.infoWinOpen = true;
            this.hideTabs = true;
          } else {
            this.fetchPolygonValues(response, vmInstance, vmInstance.fieldBuilder.fieldArea);
            this.fetchingData = false;
          }
        });
      }
    },

    fetchPolygonValues(response, vmInstance, fieldArea) {
      vmInstance.res = response;
      vmInstance.dailyIrrigation = [];
      vmInstance.irrigationDates = [];
      // let fiveDaysRequirement = 0;
      let resLength = vmInstance.res.length;

      let startingIndex = null;
      if (resLength > 8) {
        startingIndex = resLength - 8;
      } else {
        startingIndex = 0;
      }
        this.getIrrigationRatio();
      for (let i = startingIndex; i < resLength; i++) {
        let dailyInfo = vmInstance.res[i];
        if (dailyInfo[0] == null) {
          dailyInfo[0] = 0;
        } else {
          dailyInfo[0] = parseFloat(dailyInfo[0]);
        }
        vmInstance.dailyIrrigation.push(((dailyInfo[0].toFixed(3)*10.0)/(this.irrigationRatio)).toFixed(2));
        vmInstance.irrigationDates.push(dailyInfo[1]);
      }
      vmInstance.series = [{data: vmInstance.dailyIrrigation}];
      vmInstance.options = {xaxis: {categories: vmInstance.irrigationDates}};
      vmInstance.res = {Et: vmInstance.dailyIrrigation, date: vmInstance.irrigationDates} //m3/hectare

      // for (let k = 0; k < vmInstance.res.Et.length; k++) {
      //   fiveDaysRequirement += parseFloat(vmInstance.res.Et[k]);
      // }

     //
      let dailyValue = parseFloat(vmInstance.res.Et[vmInstance.res.Et.length - 5]).toFixed(2);
      let totalRequirement, fieldAreaInHectare;
      let irrigationDuration;

      if (fieldArea) {
        fieldAreaInHectare = (parseFloat(fieldArea/10000)).toFixed(2);
        totalRequirement = (fieldAreaInHectare * dailyValue).toFixed(2);
        vmInstance.getPipeSizeRatio(vmInstance.selectedPipeSize);
        irrigationDuration = (totalRequirement/vmInstance.pipeSizeRatio).toFixed(2);
      } else {
        totalRequirement = null;
        fieldAreaInHectare = null;
        irrigationDuration = null;
        fieldAreaInHectare = (50*50/10000).toFixed(2);
      }

      if (this.stopFetchingData == false) {
        if (irrigationDuration > 24) {
          this.$bvModal.show('irrigation-duration-alert');
        }

        vmInstance.infoOptions.content = vmInstance.getInfoWindowContent(dailyValue, fieldAreaInHectare, totalRequirement, irrigationDuration);
        vmInstance.infoWinOpen = true;
      }

      if (vmInstance.dailyIrrigation !== []) {
        vmInstance.fetchingData = false;
        vmInstance.pointClick = true;
      }
    },

    getPolygonIrrigation (polygon) {
      this.series = [{data:  []}];
      this.options = {xaxis: {categories:  []}};
      this.result = this.getPolygonData(polygon);
      this.hideTabs = false;
    },

    getPolygonData (polygon) {
      this.getData(polygon, this);
    },

    showOnMap (fields) {
      this.mapZoomLevel = 19;
      this.centerPosition = {lat: fields[0].markerLatitude, lng: fields[0].markerLongitude};
      this.showFieldDetails(fields[0]);
    },

    showFieldDetails (fieldInfo, map) {
      this.infoWindowPos.lat = fieldInfo.markerLatitude;
      this.infoWindowPos.lng = fieldInfo.markerLongitude;
      var fieldAreaInHectare = (parseFloat(fieldInfo.fieldArea/10000)).toFixed(2);
      this.infoOptions.content = this.showInfoContent(fieldInfo.cropType, fieldInfo.name, fieldAreaInHectare);
      this.selectedIrrigationSystemType = fieldInfo.irrigationSystem;
      this.getPipeSizeRatio(fieldInfo.pipeSize);
      this.showEditButton = true;
      this.activeField = fieldInfo;
      this.infoWinOpen = true;
      if (!this.adminUser) {
        this.stopFetchingData = false;
        this.getExistingFieldIrrigation(fieldInfo.position);
      } else {
        document.getElementById("sidebar-right").style.display= "flex";
        var vmInstance = this;
        this.filterFieldByUserSelection(fieldInfo.deviceId);
        this.filteredFieldsBySelection.map((field) => {
          var a = vmInstance.fetchHistoryValuesByField(field.key);
          if (a) {
            vmInstance.historyValues.push(a);
          }
        });
        if (vmInstance.polygonMarker) {
          vmInstance.polygonMarker.setMap(null);
        }
        vmInstance.polygonMarker = new window.google.maps.Marker({
          position: vmInstance.infoWindowPos,
          map: map
        });
      }
    },

    filterFieldByUserSelection (userId) {
      this.filteredFieldsBySelection = this.currentFields.filter(field =>
        field.deviceId === userId)
      this.selectedUserAccount = userId
    },

    getExistingFieldIrrigation (fieldJsonPosition) {
      var latLngArray = this.getLatLngArray(fieldJsonPosition);
      var aoi = {
        "type": "Polygon",
        "coordinates": latLngArray
      };
      this.getPolygonIrrigation(aoi);
    },

    getFormatedPolygon (fieldJsonPosition) {
      return this.getGeeLatLngArray(fieldJsonPosition);
    },

    getGeeLatLngArray (jsonFormat) {
      var splittedArray = jsonFormat.split("lat/lng:");
      var polygonCoordinates = [];
      var finalizedPolygonCoordinates = [];
      for (var i = 1; i < splittedArray.length; i++) {
        polygonCoordinates.push(splittedArray[i].replace('(', '').replace(')', '').replace(']', ''));
      }
      for (var x = 0; x < polygonCoordinates.length; x++) {
        var splittedEntry = polygonCoordinates[x].trim().split(',');
        finalizedPolygonCoordinates.push([splittedEntry[1].trim(), splittedEntry[0]])
      }

      return finalizedPolygonCoordinates;
    },


    getLatLngArray (jsonFormat) {
      var splittedArray = jsonFormat.split("lat/lng:");
      var polygonCoordinates = [];
      var finalizedPolygonCoordinates = [];
      for (var i = 1; i < splittedArray.length; i++) {
        polygonCoordinates.push(splittedArray[i].replace('(', '').replace(')', '').replace(']', ''));
      }
      for (var x = 0; x < polygonCoordinates.length; x++) {
        var splittedEntry = polygonCoordinates[x].trim().split(',');
        finalizedPolygonCoordinates.push([splittedEntry[0], splittedEntry[1].trim()])
      }

      return finalizedPolygonCoordinates;
    },

    formatDate(date) {
      var d = new Date(date),
          month = '' + (d.getMonth() + 1),
          day = '' + d.getDate(),
          year = d.getFullYear();

      if (month.length < 2)
          month = '0' + month;
      if (day.length < 2)
          day = '0' + day;

      var value = [year, month, day].join('-');
      return value + "T00:00:00";
    },

    editPolygon () {
        this.showUpdateButton = true;
        this.showEditButton = false;
        // Retrieve the polygon's properties

        // Create a new editable polygon with the same properties
        this.editablePolygon = new window.google.maps.Polygon({
            paths: this.activeFieldPolygon.getPath(),
            editable: true,
            // Set other polygon options as needed
        });
        this.$refs.googleMap.$mapPromise.then((map) => {
        //     // Replace the existing polygon with the new editable polygon
            this.activeFieldPolygon.setMap(null); // Remove existing polygon from the map
            this.editablePolygon.setMap(map); // Set the editable polygon on the map
        });

        // Update the reference to the activeFieldPolygon


    },
    drawing () {
      var vmInstance = this;
      this.$refs.googleMap.$mapPromise.then((map) => {

        const input = document.getElementById("place-search");
        new window.google.maps.places.Autocomplete(input);

        let googleMap = window.google.maps.drawing;
        const drawingManager = new googleMap.DrawingManager({
          drawingMode: null,
          drawingControl: true,
          drawingControlOptions: {
            position: window.google.maps.ControlPosition.LEFT_BOTTOM,
            drawingModes: [
              googleMap.OverlayType.POLYGON
            ],
          },
          markerOptions: {
            icon:
              "https://developers.google.com/maps/documentation/javascript/examples/full/images/beachflag.png",
          },
          polygonOptions: {
            strokeColor: "#F57F17",
            fillColor: "#526f2f",
            editable: true,
          },
          circleOptions: {
            strokeColor: "#FF0000",
            strokeOpacity: 0.8,
            strokeWeight: 2,
            fillColor: "#FF0000",
            fillOpacity: 0.35,
            clickable: false,
            editable: true,
            zIndex: 1,
          },
        });
        drawingManager.setMap(map);
        vmInstance.drawingManagerTool = drawingManager;
        if (!vmInstance.activeUser) {
          vmInstance.drawingManagerTool.setMap(null);
        }

        function customizePolygonAndFetchIrrigation(polygon) {
          var drawnPolygon = [];
          if (vmInstance.polygonMarker) {
            vmInstance.polygonMarker.setMap(null);
          }

          vmInstance.fieldBuilder.cropType = vmInstance.selectedCrop;
          vmInstance.fieldBuilder.irrigationSystem = vmInstance.selectedIrrigationSystemType;
          vmInstance.fieldBuilder.pipeSize = vmInstance.selectedPipeSize;
          vmInstance.fieldBuilder.name = null;

          var bounds = new window.google.maps.LatLngBounds();
          polygon.forEach(function(element){bounds.extend(element)});
          vmInstance.fieldBuilder.markerLatitude = bounds.getCenter().lat();
          vmInstance.fieldBuilder.markerLongitude =  bounds.getCenter().lng();

          var computedArea = window.google.maps.geometry.spherical.computeArea(polygon);
          vmInstance.fieldBuilder.fieldArea = parseFloat(computedArea).toFixed(2);
          vmInstance.endDrawing();

          for (var i = 0; i < polygon.getLength(); i++) {
            drawnPolygon.push(polygon.getAt(i).toUrlValue(6).split(","))
          }
          // vmInstance.currentFields.push(polygon);
          vmInstance.showSaveButton = true;

          drawnPolygon.push(polygon.getAt(0).toUrlValue(6).split(","));
          vmInstance.setFieldPositionFromCoordinates(drawnPolygon);

          vmInstance.infoWindowPos.lat = bounds.getCenter().lat();
          vmInstance.infoWindowPos.lng = bounds.getCenter().lng();
          vmInstance.infoWinOpen = false;
          vmInstance.polygonMarker = new window.google.maps.Marker({
            position: vmInstance.infoWindowPos,
            map: map
          })

          return drawnPolygon;
        }

        window.google.maps.event.addListener(drawingManager, 'polygoncomplete', function (polygon) {
          // var drawnPolygon = [];
          polygon.setEditable(true);
          vmInstance.polygon = polygon;

            vmInstance.getPolygonIrrigation(customizePolygonAndFetchIrrigation(polygon.getPath()));
          // // assuming you want the points in a div with id="info"
          // var aoi = {
          //     "type": "Polygon",
          //     "coordinates": polygon
          //   };
          //
          // vmInstance.getPolygonIrrigation(aoi);

          // window.google.maps.event.addListener(polygon.getPath(), 'insert_at', function() {
          //   var drawnInsertAtPolygon = customizePolygonAndFetchIrrigation( polygon.getPath());

          //   var aoi = {
          //     "type": "Polygon",
          //     "coordinates": drawnInsertAtPolygon
          //   };

          //   vmInstance.getPolygonIrrigation(aoi);
          // });

          // window.google.maps.event.addListener(polygon.getPath(), 'set_at', function() {
          //   var drawnSetAtPolygon = customizePolygonAndFetchIrrigation(polygon.getPath());
          //
          //
          //   var aoi = {
          //     "type": "Polygon",
          //     "coordinates": drawnSetAtPolygon
          //   };
          //
          //   vmInstance.getPolygonIrrigation(aoi);
          // });
        });
      });
    },

    setFieldPositionFromCoordinates (fieldCoordinates) {
      var position = "[";
      for (var i = 0; i < (fieldCoordinates.length - 1); i++) {
        position += `lat/lng: (${fieldCoordinates[i][0]}, ${fieldCoordinates[i][1]}), `;
      }

      position += `lat/lng: (${fieldCoordinates[fieldCoordinates.length - 1][0]}, ${fieldCoordinates[fieldCoordinates.length - 1][1]})]`;
      if (this.activeField) {
        this.activeField.position = position;
      } else {
        this.fieldBuilder.position = position;
      }
      return position;
    },

    fieldPaths (fieldsArray) {
      this.fieldDetails = [];
      if (fieldsArray.length > 0) {
        for (var index = 0; index < fieldsArray.length; index++) {
          let pathArray;

          if (fieldsArray[index].markerLatitude) {
            const markerPosition = {
              lat: fieldsArray[index].markerLatitude,
              lng: fieldsArray[index].markerLongitude
            };
            this.adminMarkers.push(markerPosition);
          }
          if (fieldsArray[index].position !== undefined) {
            pathArray = fieldsArray[index].position.replace('[','').replace(']', '').split("lat/lng: ");
            let path = [];
            for (var pathIndex = 0; pathIndex < pathArray.length; pathIndex++) {
              if (pathArray[pathIndex] !== "" ) {
                let singleLatLng;
                singleLatLng = pathArray[pathIndex].replace('(','').replace('),','').replace(')','').split(",");
                path.push({lat: parseFloat(singleLatLng[0]), lng: parseFloat(singleLatLng[1])});
              }
            }
            path.push(path[0]);
            this.fieldDetails.push({fieldInfo: fieldsArray[index], path: path});
          }
        }
        return this.fieldDetails.map (e => e.path);
      } else {
        return {lat: 0, lng: 0}
      }
    },

    getInfoWindowContent: function (value, fieldArea, totalRequirement, irrigationDuration) {
        value = this.$i18n.n(value)
        fieldArea = this.$i18n.n(fieldArea)
      if (totalRequirement) {
        totalRequirement = this.$i18n.n(totalRequirement)
        irrigationDuration = this.$i18n.n(irrigationDuration)
        return (
        `<div class="">
          <div>
            <div>
              <div class="m-2"><span style="font-weight: bold;">${this.$i18n.t(`global.water_requirement_today`)}: </span>
                ${value} ${this.$i18n.t(`global.meter_cube_per_hectare`)}
              </div>
              <div class="m-2"><span style="font-weight: bold;">${this.$i18n.t(`global.field_area_in_hectare`)}: </span>
                ${fieldArea} ${this.$i18n.t(`global.hectare`)}
              </div>
              <div class="m-2"><span style="font-weight: bold;">${this.$i18n.t(`global.total_requirement`)}: </span>
                ${totalRequirement} ${this.$i18n.t(`global.meter_cube_per_day`)}
              </div>
              <div class="m-2"><span style="font-weight: bold;">${this.$i18n.t(`global.irrigation_duration`)}: </span>
                ${irrigationDuration} ${this.$i18n.t(`global.hr_per_day`)}
              </div>
            </div>
          </div>
        </div>`);
      } else {
        return (
        `<div class="">
          <div>
            <div>
              <div class="m-2"><span style="font-weight: bold;">${this.$i18n.t(`global.crop_evapotranspiration`)}: </span>
                ${value} ${this.$i18n.t(`global.mm_per_day`)}
              </div>
            </div>
          </div>
        </div>`);
      }
    },

     showInfoContent: function (cropType, fieldName, fieldArea) {
      return (
      `<div class="">
        <div>
          <div>
            <div class="m-2"><span style="font-weight: bold;">${this.$i18n.t(`global.field_name`)}: </span>
              ${fieldName}
            </div>
            <div class="m-2"><span style="font-weight: bold;">${this.$i18n.t(`crops.crop_type`)}: </span>
              ${this.$i18n.t(`crops.types.${cropType}`)}
            </div>
            <div class="m-2"><span style="font-weight: bold;">${this.$i18n.t(`global.field_area`)}: </span>
              ${fieldArea} ${this.$i18n.t(`global.hectare`)}
            </div>
          </div>
        </div>
      </div>`);
    },

    showNoData: function () {
      return (
      `<div class="">
        <b>No data available at this moment</b>
      </div>`);
    },

    async getCenter () {

    }
  },

  computed: {
    users () {
      userStore.dispatch('fetchUsers');
      return userStore.state.users.map((e, index) => ({ index: index + 1, device_id: e.deviceID }));
    },

    mobileViewport () {
      return this.windowWidth < 1025;
    },

    ipadViewPort() {
      return (this.windowWidth > 769 && this.windowWidth < 1200);
    },

    mobileButton () {
      if (this.mobileViewport) {
        return 'mobile-draw-button';
      } else {
        return '';
      }
    },

      rectangleWindow () {
          if (this.mobileViewport) {
              return 'mobile-rectangle-window';
          } else {
              return 'rectangle-window';
          }
      },

    locationCenter () {
      var location;
      if (this.currentFields.length > 0) {
        var fieldCount = this.currentFields.length;
        var lastfield = this.currentFields[fieldCount - 1];
        location =  { lat: lastfield.markerLatitude, lng: lastfield.markerLongitude };
      } else if (this.detectedLocation && this.currentLocationCenter) {
        location =  { lat: this.currentLocationCenter.lat(), lng: this.currentLocationCenter.lng() };
      }

      return location;
    }
  },

  created () {
    // this.getCenter();
    this.getDeviceInfo();
    window.addEventListener("resize", () => {
      this.windowWidth = window.innerWidth;
    });

    firebase.auth.onAuthStateChanged(user => {
      var vmInstance = this;
      if (user) {
        vmInstance.deviceId = user.uid;
        vmInstance.activeUser = user;
        if (!user.email) {
          localStorage.activeDevice = vmInstance.deviceId;
        } else {
          localStorage.activeDevice = user.email;
        }
        this.$refs.googleMap.$mapPromise.then((map) => {
          vmInstance.drawingManagerTool.setMap(map);
        });
      } else {
        this.showGetIrrigationModal();
        localStorage.activeDevice = null;
        this.activeUser = null;
        this.adminUser = false;
        this.adminAccess = false;
        this.stopFetchingData = true;
        this.hideTabs = true;
        this.infoWinOpen = false;
        this.currentFields = [];
      }
    });
  },

  watch: {
    activeUser: function() {
      this.fetchFields();
    },

    deviceId: function() {
      this.fetchFields();
    },

    locationCenter: function (val) {
      if (val) {
        this.center = val;
        this.zoom = 14
      }
    },

      currentFields: {
        handler(val) {
            setTimeout (() => {

              if (val.length > 0)  {
                var vmInstance = this;

                vmInstance.mapPolygons.forEach(function (polygon) {
                  polygon.setMap(null);
                });
                this.fieldPaths(val);

                var a = this.adminMarkers;

                this.$refs.googleMap.$mapPromise.then((map) => {
                    if (this.handled === false) {
                        this.handled = true;

                    const markers = a.map((position) => {
                      const marker = new window.google.maps.Marker({
                        position: position,
                        map: map});
                        return marker;
                      });


                      new MarkerClusterer({ markers, map });
                    }

                  this.fieldDetails.forEach(function (item, index) {
                    const fieldPolygon = new window.google.maps.Polygon({
                      paths: item.path,
                      strokeColor: "#9c5252",
                      strokeOpacity: 0.8,
                      strokeWeight: 2,
                      fillColor: "#FF0000",
                      fillOpacity: 0.35,
                      editable: false
                    });
                    fieldPolygon.setMap(map);


                    vmInstance.mapPolygons.push(fieldPolygon);
                    window.google.maps.event.addListener(fieldPolygon, 'click', function () {
                        vmInstance.activeFieldPolygon = fieldPolygon;
                      vmInstance.showFieldDetails(vmInstance.fieldDetails[index].fieldInfo, map)
                    });
                  });
                });

              } else {
                this.drawPolygon();
                this.toggleIntro();

                this.mapPolygons.forEach(function (polygon) {
                  polygon.setMap(null);
                });
                this.mapPolygons = [];
              }
          },  2000);
        },
        deep: true
     },

    fetchingData: function (value) {
      if (value) {
        this.res = [];
        if (this.pointMarker) {
          this.pointMarker.setMap(null);
          this.infoOptions.content = null;
          this.infoWinOpen = false;
        }
      }
    }
  },

  mounted () {
    this.drawing();
    this.requestLocationPermission();
  }
};
</script>

<style lang="scss" scoped>
.main {
// Adding z-index to make buttons appear over the map.
  .control-panel {
    width: 100%;
    position: absolute;
    z-index: 1;
  }
}
  @media (min-width: 577px) {
    .help-text {
      display: none;
    }
  }

  @media (max-width: 768px) {
    .help-text {
      font-size: 1.25rem;
      font-weight: 600;
      background: #EAEAEA;
      height: fit-content;
      padding: 1rem;
    }

    .mobile-rectangle-window {
      margin-left: auto;
      width: 255px;
      height: 200px;
      margin-right: auto;
      margin-top: 25%;
    }
  }

.rectangle-window {
  margin-left: auto;
  width: 245px;
  height: 183px;
  margin-right: auto;
  margin-top: 17%;
}


.table th, .table td {
  display: flex;
}

.margin-top {
  margin-top: 0.25rem !important;
}

</style>
