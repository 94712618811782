<template>
		<gmap-map
				:center="center"
				:zoom="zoom"
				ref="googleMap"
				@click="showLoginModal()"
				:options='{ mapTypeControl: mapTypeControl,
					zoomControl: true, streetViewControl: false, rotateControl: true }'
        class="full-screen-map"
				map-type-id="hybrid">
				<gmap-info-window
					:options="infoOptions"
					:position="infoWindowPos"
					:opened="infoWinOpen"
					:content="infoContent"
					@closeclick="infoWinOpen=false">
				</gmap-info-window>
		</gmap-map>
</template>
<script>
import { mapState } from 'vuex';

export default {
  name: 'MapView',

	data() {
		return {
			center: { lat: 33.89, lng: 35.90 },
      PinElement: null,
			infoOptions: {
				pixelOffset: {
					width: 0,
					height: -35
				},
				content: ''
			},
			infoWindowPos: {
				lat: 0,
				lng: 0
			},
			infoWinOpen: false,
			infoContent: '',
			zoom: 11,
			mapTypeControl: false,
			fieldDetails: [],
			pipeSizes:[
				"32 mm, 1 inch",
				"40 mm, 1.25 inch",
				"50 mm, 1.5 inch",
				"63 mm, 2 inch",
				"75 mm, 2.5 inch",
				"90 mm, 3 inch",
				"110 mm, 4 inch",
				"140 mm, 5 inch",
				"160 mm, 6 inch"
			],
		}
	},
	computed: {
		// Get user and fields from store
		...mapState(['user', 'fields', 'activeField', 'draftField', 'mapPolygons', 'fetchingResults', 'irrigationWindow']),
	},

  created() {
    this.$on('closeInfoWindow', this.closeInfoWindow);
  },

  mounted () {
    this.$emit('map-loaded', this.$refs.googleMap);
  },

	watch: {
		// Watch for changes in fields
    fields() {
      this.fieldDetails = [];
      this.drawFieldsOnMap();
    },

    '$i18n.locale': function() {
      // Do something when the locale changes
      if (this.activeField) {
        this.infoOptions.content = this.showInfoContent(this.activeField.cropType, this.activeField.name, this.activeField.fieldArea);
      }
    },

    irrigationWindow: function(newVal) {
      this.infoWinOpen = true;
      if (this.draftField) {
        this.infoWindowPos.lat = this.draftField.markerLatitude;
        this.infoWindowPos.lng = this.draftField.markerLongitude;
      } else if (this.activeField) {
        this.infoWindowPos.lat = this.activeField.markerLatitude;
        this.infoWindowPos.lng = this.activeField.markerLongitude;
      }
      this.infoOptions.content = newVal;
    }
  },

	methods: {
    closeInfoWindow() {
      this.infoWinOpen = false;
    },

    showLoginModal () {
      if (!this.user) {
        this.$emit('showLoginModal');
      }
    },

		fieldPaths () {
      if (this.fields) {
        // Get field paths in a format that can be used by google maps
        for (var index = 0; index < this.fields.length; index++) {
          let pathArray;
          // Check if field has a position
          if (this.fields[index].position !== undefined) {
            // Get path array
            pathArray = this.fields[index].position.replace('[','').replace(']', '').split("lat/lng: ");
            let path = [];
            // Loop through path array and create path
            for (var pathIndex = 0; pathIndex < pathArray.length; pathIndex++) {
              // Check if path is not empty
              if (pathArray[pathIndex] !== "" ) {
                let singleLatLng;
                // Check if path is a single lat/lng or a pair
                singleLatLng = pathArray[pathIndex].replace('(','').replace('),','').replace(')','').split(",");
                // Add lat/lng to path
                path.push({lat: parseFloat(singleLatLng[0]), lng: parseFloat(singleLatLng[1])});
              }
            }
            // Add first point to end of path to close polygon
            path.push(path[0]);
            // Add field info and path to fieldDetails array
            this.fieldDetails.push({fieldInfo: this.fields[index], path: path});
          }
        }
        // Return array of paths
        return this.fieldDetails.map (e => e.path);
      }
    },

		drawFieldsOnMap () {
			// Check if fields exist

			if (this.fields) {
				// Get instance of vue component
				let vmInstance = this;
				// Get field paths in a format that can be used by google maps
				vmInstance.fieldPaths();
        var listOfMarkers = [];
				// Wait for the google map to be ready before drawing the fields
				this.$refs.googleMap.$mapPromise.then((map) => {
					// Draw polygon for each field
					vmInstance.fieldDetails.forEach(function (item, index) {
						// Create polygon
						const fieldPolygon = new window.google.maps.Polygon({
							// Set polygon attributes
							paths: item.path,
							strokeColor: "#9c5252",
							strokeOpacity: 0.8,
							strokeWeight: 2,
							fillColor: "#FF0000",
							fillOpacity: 0.35,
							editable: false,
              saved: true
						});
						// Add polygon to map
            fieldPolygon.name = item.fieldInfo.name;
						fieldPolygon.setMap(map);

            if (!vmInstance.mapPolygons.find(polygon => polygon.path === item.path)) {
              vmInstance.$store.commit('addMapPolygon', fieldPolygon);
            }

						// Add marker for each polygon
						let fieldMarker = new window.google.maps.Marker({
							position: { lat: item.fieldInfo.markerLatitude, lng: item.fieldInfo.markerLongitude },
							map: map
						});
            listOfMarkers.push(fieldMarker);

            // vmInstance.$store.commit('setMapMarkers', fieldMarker);
						// Add click event listener to polygon
						window.google.maps.event.addListener(fieldPolygon, 'click', function () {
              vmInstance.mapPolygons.forEach(polygon => {
                if (polygon !== this) {
                  polygon.setOptions({ strokeColor: '#9c5252' }); // Set to default stroke color
                }
              });
              fieldPolygon.setOptions({strokeColor: '#fcba03'});
              vmInstance.$store.commit('setActiveField', item.fieldInfo);
              vmInstance.$emit('fetchFieldIrrigation', fieldPolygon);
              vmInstance.$store.commit('setFetchingResults', true);
              vmInstance.$store.commit('setActivePolygon', fieldPolygon);
              vmInstance.$store.commit('setActiveMarker', fieldMarker);
              vmInstance.showFieldDetails(vmInstance.fieldDetails[index].fieldInfo);
						});
					});
          // new MarkerClusterer({ listOfMarkers, map });

				});
			}
		},

		showFieldDetails (fieldInfo) {
      this.infoWindowPos.lat = fieldInfo.markerLatitude;
      this.infoWindowPos.lng = fieldInfo.markerLongitude;
      var fieldAreaInHectare = (parseFloat(fieldInfo.fieldArea/10000)).toFixed(2);
      this.infoOptions.content = this.showInfoContent(fieldInfo.cropType, fieldInfo.name, fieldAreaInHectare);
      this.selectedIrrigationSystemType = fieldInfo.irrigationSystem;
      this.getPipeSizeRatio(fieldInfo.pipeSize);
      this.infoWinOpen = true;
    },

		showInfoContent: function (cropType, fieldName, fieldArea) {
      return (
      `<div class="">
        <div>
          <div>
            <div class="m-2"><span style="font-weight: bold;">${this.$i18n.t(`global.field_name`)}: </span>
              ${fieldName}
            </div>
            <div class="m-2"><span style="font-weight: bold;">${this.$i18n.t(`crops.crop_type`)}: </span>
              ${this.$i18n.t(`crops.types.${cropType}`)}
            </div>
            <div class="m-2"><span style="font-weight: bold;">${this.$i18n.t(`global.field_area`)}: </span>
              ${fieldArea} ${this.$i18n.t(`global.hectare`)}
            </div>
          </div>
        </div>
      </div>`);
    },

		getPipeSizeRatio (pipeSize) {
      switch(this.pipeSizes.indexOf(pipeSize)) {
        case 0:
          this.pipeSizeRatio = 2.6;
          break;
        case 1:
          this.pipeSizeRatio = 4.0;
          break;
        case 2:
          this.pipeSizeRatio = 6.0;
          break;
        case 3:
          this.pipeSizeRatio = 11.0;
          break;
        case 4:
          this.pipeSizeRatio = 17.0;
          break;
        case 5:
          this.pipeSizeRatio = 24.0;
          break;
        case 6:
          this.pipeSizeRatio = 42.0;
          break;
        case 7:
          this.pipeSizeRatio = 66.0;
          break;
        case 8:
          this.pipeSizeRatio = 90.0;
          break;
      }
    },
	}
}
</script>

<style lang="scss" scoped>

.full-screen-map {
  width: 100%;
  height: calc(100% - 1rem); /* Replace 50px with the height of your navbar */
  margin-top: 1rem; /* Replace 50px with the height of your navbar */
  overflow: hidden;
  position: fixed;
}

</style>
