<template>
 <div class="d-flex mt-1 d-md-none">
		<b-button-group vertical>
			<b-button
				@click="goToDetectedLocation()" size="md" class="my-1" variant="light">
				<img src="../../assets/locate-me.png"/>
			</b-button>
			<b-button
				@click="updateZoom('in')" size="md" class="my-1" variant="light">
				<b-icon icon="zoom-in" aria-hidden="true"></b-icon>
			</b-button>
			<b-button
				size="md" @click="updateZoom('out')" class="my-1" variant="light">
				<b-icon icon="zoom-out" aria-hidden="true"></b-icon>
			</b-button>
		</b-button-group>
	</div>
</template>
<script>
export default {
  name: "MobileZoomControlButtons",


  data() {
    return {
      zoom: 11,
    }
  },

	methods: {
		updateZoom (type) {
			// zoom in case
      if (type === 'in') {
        if (this.zoom < 20) {
          this.zoom += 1;
        }
				// zoom out case
      } else if  (type === 'out') {
        if (this.zoom > 1) {
          this.zoom -= 1;
        }
      }
    },
	}
}
</script>
