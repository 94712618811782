// import Vue from 'vue'
// import Vuex from 'vuex'
import * as firebase from '../firebase'

// Vue.use(Vuex);
// // realtime firebase

// const fieldStore = new Vuex.Store({
//   state: {
//     fields: []
//   },

//   getters:{
//     fields: state =>  {
//       return state.fields
//     },
//   },

//   mutations: {
//     setFields(state, payload) {
//       state.fields = payload
//     },

//     updateFieldPosition(state, arg) {
//       if (firebase.auth.currentUser) {
//         state.fields;
//         firebase.fieldsCollection.child("/" + arg.fieldKey).child("position").set(arg.updatedPath);
//       }
//     },

//     saveNewField(state, arg) {
//       if (firebase.auth.currentUser) {
//         var newFieldKey = firebase.fieldsCollection.push().key;
//         var fieldParams = arg.fieldParams;
//         fieldParams.deviceId = firebase.auth.currentUser.l;
//         firebase.fieldsCollection.child("/" + newFieldKey).set(fieldParams);
//       }
//     },

//     deleteField(state, fieldKey) {
//       if (firebase.auth.currentUser) {
//         state.fields;
//         firebase.fieldsCollection.child("/" + fieldKey).remove();
//       }
//     },
//   },

//   actions: {
//     async editFieldPath({ commit }, arg) {
//       commit('updateFieldPosition', {fieldKey: arg.fieldKey, updatedPath: arg.updatedPath});
//     },

//     async saveField({ commit }, arg) {
//       commit('saveNewField', {fieldParams: arg});
//     },

//     async deleteField({ commit }, fieldKey) {
//       commit('deleteField', fieldKey);
//     },

//     async fetchFieldsByDevice({ commit }, deviceId) {
//       let fieldsArray = [];

//       await firebase.fieldsCollection.orderByChild("deviceId").equalTo(deviceId).on('value', function(snapshot) {
//         snapshot.forEach(function(childSnapshot) {
//           var fieldKey = {key: childSnapshot.key};
//           fieldsArray.push(Object.assign(fieldKey, childSnapshot.val()));
//         });
//       });

//       commit('setFields', fieldsArray);
//     },

//     async fetchFields({ commit }) {
//       await firebase.fieldsCollection.on('value', function(snapshot) {
//         let fieldsArray = [];
//         snapshot.forEach(function(childSnapshot) {
//           var fieldKey = {key: childSnapshot.key};
//           fieldsArray.push(Object.assign(fieldKey, childSnapshot.val()));
//           // Fill the local data property with Firebase data
//           commit('setFields', fieldsArray);
//         });
//       });
//     },
//   }
// })

// export default fieldStore;

class FieldStore {
  async fetchFieldsByDevice(deviceId) {
    let fieldsArray = [];
    if (deviceId) {
        var query = firebase.fieldsCollection.orderByChild("deviceId").equalTo(deviceId);
        const snapshot = await query.once('value');
        snapshot.forEach(function(childSnapshot) {
            var fieldKey = {key: childSnapshot.key};
            fieldsArray.push(Object.assign(fieldKey, childSnapshot.val()));
        });
    }
    return fieldsArray;
  }

  async fetchFields() {
    let fieldsArray = [];
    var query = firebase.fieldsCollection;
    await query.on('value', function(snapshot) {
      snapshot.forEach(function(childSnapshot) {
        var fieldKey = {key: childSnapshot.key};
        fieldsArray.push(Object.assign(fieldKey, childSnapshot.val()))
      });
    });
    return fieldsArray;
  }

  async deleteField(fieldKey) {
    if (firebase.auth.currentUser || localStorage.activeDevice) {
      await firebase.fieldsCollection.child("/" + fieldKey).remove();
    }
  }

  async saveNewField(arg, deviceId) {
    let newFieldKey = firebase.fieldsCollection.push().key;
    let fieldParams = arg;
    const current = new Date();
    const date = `${current.getDate()}/${current.getMonth()+1}/${current.getFullYear()}`;
    fieldParams.createdAt = date;
    fieldParams.deviceId = deviceId;
    await firebase.fieldsCollection.child("/" + newFieldKey).set(fieldParams);
  }

  async saveDemoField(arg, deviceId) {
    if (firebase.auth.currentUser) {
      let newFieldKey = firebase.unsavedFieldsCollection.push().key;
      let fieldParams = arg;
      const current = new Date();
      const date = `${current.getDate()}/${current.getMonth()+1}/${current.getFullYear()}`;
      fieldParams.createdAt = date;
      if (!firebase.auth.currentUser.email) {
        fieldParams.deviceId = deviceId;
        fieldParams.createdAt = deviceId;
      } else {
        fieldParams.deviceId = firebase.auth.currentUser.email;
      }
      await firebase.unsavedFieldsCollection.child("/" + newFieldKey).set(fieldParams);
      return newFieldKey;
    }
  }

  async editField(fieldKey, arg) {
    arg.updatedAt = new Date();
    await firebase.fieldsCollection.child("/" + fieldKey).set(arg);
  }
}

export default new FieldStore
