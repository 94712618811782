<!-- ************************* GMAIL AUTHENTICATION ************************ -->
<template>
<!-- ***************************** START LOGIN ***************************** -->
    <div style="height: 140px;

    position: static; z-index: 100000;padding-top: 0.75rem;padding-left: 1rem;padding-right: 1rem;" v-if="!user">
<!-- - - - - - - - - - - - - - - - LOGIN BUTTON - - - - - - - - - - - - - -  -->
      <div id="sign-in-button"/>
      <b-dropdown :class="loginWithMobileClicked ? 'mobile-clicked' : ''" direction="down" class="login-methods" :text="$t(`global.login`)" variant="primary">
        <b-dropdown-item  @click.prevent="loginWithGoogle()"><font-awesome-icon :style="{ color: 'blue' }" :icon="[ 'fab', 'google' ]" /> {{ $t(`global.sign_in_with_google`) }}</b-dropdown-item>
        <b-dropdown-item  @click.prevent="loginWithMobile()"><font-awesome-icon style="margin-right: 0.5rem" :icon="['fas', 'mobile-alt']" />{{ $t(`global.sign_in_with_mobile`) }}</b-dropdown-item>

        <div class="remember-me">
          <input type="checkbox" id="rememberMe" v-model="rememberMe">
          <label for="rememberMe">{{ $t(`global.remember_me`) }}</label>
        </div>
      </b-dropdown>

      <MobileNumberModal @submitOtp="verifyOtp" @submitPhoneNumber="handlePhoneNumber"/>
    </div>
<!-- ************************** END LOGIN ********************************** -->

<!-- ************************* START LOGOUT ******************************** -->
    <div style="display: flex" v-else>
      <b-button v-if="mobileViewPort"  @click="gmailLogout()" class="mobile-logout" style="z-index:1000; height: 3.5rem" variant="transparent" v-b-tooltip.hover.bottom :title="$t(`global.sign_out_from`) + ' ' + (user.email || user.phoneNumber)">
					<b-icon
						icon="power" scale="1.5" variant="white">
					</b-icon>
				</b-button>
      <b-button v-else id="logged-in" @click="gmailLogout()" variant="transparent">
        <b-list-group horizontal>
          <b-list-group-item style="z-index: 1000">
            <font-awesome-icon :style="{ color: 'blue' }" :icon="[ 'fab', 'google' ]" />
          </b-list-group-item>
              <b-list-group-item active>
              {{ $t(`global.sign_out_from`) + " " + (user.email || user.phoneNumber)}}
          </b-list-group-item>
        </b-list-group>
      </b-button>
    </div>
</template>

<script>
import * as firebase from '../../firebase';
import { mapState } from 'vuex';
import { Capacitor } from '@capacitor/core';
import MobileNumberModal from './MobileNumberModal';

export default {
  name: "GmailLogin",

  data() {
    return {
      phoneNumber: null,
      confirmationResult: null,
      rememberMe: false,
    };
  },

  props: {
    mobileViewPort: { type: Boolean, default: false },
  },

  components: {
    MobileNumberModal
  },

  computed: {
    ...mapState(['user', 'fields', 'loginWithMobileClicked']),

    tooltipText () {
      return this.user ? `Sign out from ${this.user.email}` : ''
    }
  },

  methods: {
    async loginWithFacebook () {
      try {
        const provider = new firebase.firebase.auth.FacebookAuthProvider();
        const result = await firebase.firebase.auth().signInWithPopup(provider);
        this.$store.dispatch('login', result.user);
        if (this.rememberMe) {
          localStorage.setItem('user', JSON.stringify(result.user));
        } else {
          localStorage.removeItem('user');
        }
      } catch (error) {
        console.error('Error during sign in:', error);
      }
    },

    async loginWithMobile() {
      this.$store.commit('setLoginWithMobile', true);
      this.$bvModal.show('mobile-number-modal');
    },

    verifyOtp(otp) {
      let self = this;
      this.confirmationResult.confirm(otp)
      .then((result) => {
        // User signed in successfully.
        self.$store.dispatch('login', result.user);
      })
      .catch((error) => {
        console.log(error)
      });
    },

    handlePhoneNumber(phoneNumber) {
      this.phoneNumber = phoneNumber;
      var recaptchaVerifier = new firebase.firebase.auth.RecaptchaVerifier('sign-in-button', {
        'size': 'invisible'
      });
      let self = this;
      recaptchaVerifier.render().then(function() {
        firebase.firebase.auth().signInWithPhoneNumber(phoneNumber, recaptchaVerifier).then(function (confirmationResult) {
          self.confirmationResult = confirmationResult;
        }).catch(function (error) {
          console.error(error);
        });
      });
    },

    async loginWithGoogle() {
      if (Capacitor.isNative) {
        try {
          if (Capacitor.platform === 'android') {
            window.plugins.googleplus.login(
              {
                'scopes': 'profile email',
                'webClientId': '947426181099-don6tl8geu6crj5ctskjfufcqsvfv2np.apps.googleusercontent.com',
                'offline': true,
              },
              (user) => {
                this.$store.dispatch('login', user);
                if (this.rememberMe) {
                  localStorage.setItem('user', JSON.stringify(user));
                } else {
                  localStorage.removeItem('user');
                }
              },
              function (msg) {
                alert('error: ' + msg);
              }
            );
          } else {
              window.plugins.googleplus.login(
              {
                'webClientId': '947426181099-obmdi2kg49j715d0v032b9jr0ih5egm2.apps.googleusercontent.com',
                'offline': true,
              },
              (user) => {
                this.$store.dispatch('login', user);
                if (this.rememberMe) {
                  localStorage.setItem('user', JSON.stringify(user));
                } else {
                  localStorage.removeItem('user');
                }
              },
              function (msg) {
                alert('error: ' + msg);
              }
            );
          }

        } catch (error) {
          console.error('Error during sign in:', error);
        }
      } else {
        try {
          const provider = new firebase.firebase.auth.GoogleAuthProvider();
          const result = await firebase.firebase.auth().signInWithPopup(provider);
          this.$store.dispatch('login', result.user);
          if (this.rememberMe) {
            localStorage.setItem('user', JSON.stringify(result.user));
          } else {
            localStorage.removeItem('user');
          }
        } catch (error) {
          console.error('Error during sign in:', error);
        }
      }
    },

    gmailLogout () {
      firebase.firebase.auth().signOut()
      .then(() => {
        localStorage.removeItem('user');
        this.$store.dispatch('logout');
        window.location.reload();
      })
      .catch((error) => {
        // An error happened.
        console.error('Error during sign out:', error);
      });
    },
  },
};
</script>

<style lang="scss" scoped>

.rtl {
  .list-group-horizontal > .list-group-item:first-child {
    border-bottom-right-radius: 0.25rem !important;
    border-top-right-radius: 0.25rem !important;
    border-bottom-left-radius: 0 !important;
    border-top-left-radius: 0 !important;
  }

  .list-group-horizontal > .list-group-item + .list-group-item.active {
    border-bottom-right-radius: 0 !important;
    border-top-right-radius: 0 !important;
    border-bottom-left-radius: 0.25rem !important;
    border-top-left-radius: 0.25rem !important;
  }
}

.mobile-clicked {
  display: none;
}
</style>
