<template>
  <div id="app">
    <router-view/>
  </div>
</template>
<script>
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';

export default {
  components: {
  },

  name: 'App',

  methods: {

  }
};
</script>

<style>
  @import './assets/styles/global.scss';
  @import './assets/styles/rtl.scss';
</style>
