<template>
  <b-modal centered scrollable @cancel="clearFields" id="new-field-modal">
    <b-container fluid style="max-height: 24rem;">
      <FieldForm :field="field" :error="error"/>
    </b-container>

    <template #modal-header="{ close }">
      <h5>{{$t(`my_fields.save_your_field`)}}</h5>
      <b-button size="sm" variant="light" @click="close()">
        X
      </b-button>
    </template>
    <template #modal-footer="{ cancel }">
        <b-button align-v="end"  size="sm" variant="primary" @click="cancel()">
            {{ $t(`global.cancel`) }}
        </b-button>
      <b-button size="sm" variant="success" @click="formSubmitted()">
        {{ $t(`global.save`) }}
      </b-button>
     </template>
  </b-modal>
</template>

<script>
import FieldForm from './FieldForm';
import store from '../../stores/FieldStore';
import { mapState } from 'vuex';

// import historyStore from '../../stores/HistoryStore'

export default {
  name: 'FieldNew',

  components: {
    FieldForm
  },

  data () {
    return {
      error: null
    }
  },

  props: {
    field: { type: Object },
    currentFields: { type: Array }
  },

  computed: {
		...mapState(['fields', 'draftField', 'user']),
	},

  methods: {
    async formSubmitted () {
      this.draftField.deviceId = this.user.email || this.user.phoneNumber;
      // we can do var key = await store.saveNewField(this.draftField, this.user.email); to get the stored field id
      var key = await store.saveNewField(this.draftField, this.user.email || this.user.phoneNumber);
      this.draftField.key = key;
      this.$store.commit('addField', this.draftField);
      this.$root.$emit('bv::hide::modal', 'new-field-modal');
      this.$store.commit('setActivePolygon', null);
      this.$store.commit('setActiveField', null);
    },

    validateUniqFieldName () {
      var name = this.field.name;
      var error = this.$i18n.t(`global.name_already_exists`);
      var isUniq = true;
      this.fields.forEach(function(field) {
        if(field.name == name) {
          isUniq = false;
        }
      });
      if(!isUniq) {
        this.error = error;
      }
    },

    async saveDemoField () {
      this.field.deviceId = localStorage.activeDevice;
      await store.saveDemoField(this.field, localStorage.activeDevice);
    },

    clearFields () {
      this.saveDemoField();
      this.$emit('clearFields');
    },

    formattedDate () {
      var today = new Date();
      var formattedDate;
      var month = today.toLocaleString('default', { month: 'short' });
      var days = today.toLocaleString('default', { day: 'numeric' });
      if(days < 10)
      {
        days='0'+days;
      }
      formattedDate = days + "-" + month;
      return formattedDate;
    }
  },
}
</script>
