<template>
  <div class="irrigation-models">
    <b-modal @ok="showPipeSizeModal" @cancel="clearField" scrollable id="irrigation-system-modal">
      <div class="my-4 form-check" v-for="(irrigationSystemType, index) in irrigationSystemTypes" :key="index">
        <input v-if="$te(`irrigations.${irrigationSystemType}`)" class="form-check-input" v-model="selectedIrrigationSystemType" type="radio" :name="irrigationSystemType" :id="irrigationSystemType" :value="irrigationSystemType">
        <label v-if="$te(`irrigations.${irrigationSystemType}`)" class="form-check-label" :name="irrigationSystemType" :for="irrigationSystemType">
          {{ $t(`irrigations.${irrigationSystemType}`) }}
        </label>
      </div>
      <template #modal-header="{ close }">
        <h5>{{$t(`irrigations.irrigations_title`)}}</h5>
        <b-button ref="Close" size="sm" variant="light" @click="close()">
          X
        </b-button>
      </template>
      <template  #modal-footer="{ ok }">
        <b-button size="md" @click="clearField()">{{ $t(`global.cancel`) }}</b-button>
        <b-button size="md" :disabled="disabledSave" variant="primary" @click="ok()">{{ $t(`global.next`) }}</b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>

export default {
  name: "IrrigationSystemModal",

  data () {
    return {
      selectedIrrigationSystemType: '',
      disabledSave: true,
      irrigationSystemTypes: [
        'Drip',
        'Bubbler',
        'Microsprinkler',
        'Solid-set impact sprinkler',
        'Center Pivot',
        'Linear move',
        'Hand-move sprinkler',
        'Boom sprayer',
        'Gun',
        'Surface-Basin',
        'Surface-Furrow'
      ],
      irrigationRatio: 1,
    }
  },

  watch: {
    "selectedIrrigationSystemType": function(val) {
      if (val) {
        this.disabledSave = false;
      }
    },
  },

  methods: {

    showPipeSizeModal () {
      if (this.selectedIrrigationSystemType) {
        // this.getIrrigationRatio();
        this.$store.commit('setDraftIrrigationSystem', this.selectedIrrigationSystemType);
        this.$emit('showPipeSizeModal');
        //we need to calculate the irrigation ratio in store
        // this.$emit('showPipeSizeModal', this.selectedIrrigationSystemType, this.irrigationRatio);
      }
    },

    getIrrigationRatio () {
      switch(this.irrigationSystemTypes.indexOf(this.selectedIrrigationSystemType)) {
        case 0:
          this.irrigationRatio = 0.85;
          break;
        case 1:
          this.irrigationRatio = 0.8;
          break;
        case 2:
          this.irrigationRatio = 0.8;
          break;
        case 3:
          this.irrigationRatio = 0.85;
          break;
        case 4:
          this.irrigationRatio = 0.85;
          break;
        case 5:
          this.irrigationRatio = 0.8;
          break;
        case 6:
          this.irrigationRatio = 0.8;
          break;
        case 7:
          this.irrigationRatio = 0.5;
          break;
        case 8:
          this.irrigationRatio = 0.5;
          break;
      }
    },

    clearField () {
      this.$refs.Close.click();
      this.$emit('clearDraftSelection');
    }
  }
}
</script>
