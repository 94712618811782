<template>
	<!-- Control Panel which includes the buttons that are under the header (top bar) -->
	<div class="control-panel my-2">
    <!-- DIV#1 that contains the buttons on the right side of the control panel -->
    <b-button-toolbar class="d-flex justify-content-md-end">
      <!-- DIV#2 that contains the buttons on the left realisation button, guide icon, and login button -->
      <div class="d-flex my-1">
        <!-- REALISATION BUTTON -->
        <b-button style="z-index:1000" variant="transparent" v-b-tooltip.hover.bottom :title="$t(`realisation.realisation`)">
          <b-icon v-b-modal.realisation-modal icon="info-circle-fill" scale="1.5" variant="white">
          </b-icon>
        </b-button>
        <Realisation />
        <!-- USER GUIDE BUTTON -->
        <b-button style="z-index:1000" @click="toggleIntro()" variant="transparent" v-b-tooltip.hover.bottom :title="$t(`global.user_guide`)">
          <b-icon icon="book" scale="1.5" variant="white"></b-icon>
        </b-button>
        <b-button style="z-index:1000" @click="measureFieldArea()" variant="transparent" v-b-tooltip.hover.bottom :title="$t(`global.measure_area`)">
          <b-icon icon="rulers" scale="1.5" variant="white"></b-icon>
        </b-button>

      </div>
      <!-- END OF DIV#2 -->
    </b-button-toolbar>
  </div>
</template>
<script src="https://unpkg.com/intro.js/intro.js"/>
<script>

import Realisation from './Realisation';
import introJs from 'intro.js';
import FieldAreaModal from './FieldAreaModal';

export default {
  name: 'ControlPanel',

  props: {
    mobileViewPort: { type: Boolean, default: false },
    map: { type: Object },
    user: { type: Object },
  },

	components: {
		Realisation,
    FieldAreaModal
	},

	data() {
		return {
      drawingManagerTool: null,
		}
	},

  methods: {
    clearMap () {
      this.$emit('clearMap')
    },

    measureFieldArea () {
      this.$emit('measureFieldArea');
    },

    toggleIntro () {
      let vmInstance = this;
      this.$store.commit('setUserGuideMode', true);
      let intro = introJs();
      vmInstance.showHelpText = false;
      let startPos1 = { lat: 33.911670, lng: 36.056760 };
      let endPos1 =   { lat: 33.912017, lng: 36.057752 };
      let endPos2 = {lat: 33.911670, lng: 36.056760 };
      let endPos3 =   { lat: 33.911341, lng: 36.058744 };
      let endPos4 = {lat: 33.910797, lng: 36.058036};
      var completeField;
      var polylines = [];
      var a = [];
      function DrawPolyline(vmInstance, startPos, endPos, polyline) {
        vmInstance.map.$mapPromise.then((map) => {
          let googleMap = window.google.maps.drawing;
          const drawingManager = new googleMap.DrawingManager({
            drawingMode: null,
            drawingControl: true,
            drawingControlOptions: {
              position: window.google.maps.ControlPosition.LEFT_BOTTOM,
              drawingModes: [
                googleMap.OverlayType.POLYGON
              ],
            },
            markerOptions: {
              icon:
                "https://developers.google.com/maps/documentation/javascript/examples/full/images/beachflag.png",
            },
            polygonOptions: {
              strokeColor: "#8B4513",
              fillColor: "#98FB98",
              editable: true,
            },
            circleOptions: {
              strokeColor: "#FF0000",
              strokeOpacity: 0.8,
              strokeWeight: 2,
              fillColor: "#FF0000",
              fillOpacity: 0.35,
              clickable: false,
              editable: true,
              zIndex: 1,
            },
          });
          vmInstance.drawingManagerTool = drawingManager

          // document.getElementsByClassName("gmnoprint")[0].children[1].children[0].click();

          const pos2 = {
            lat: startPos.lat,
            lng: startPos.lng,
          };
          if (a.length !== 0) {
            for (let i = 0; i < a.length; i++) {
              a[i].setMap(null);
            }
          }
          var marker2 = new window.google.maps.Marker({
            position: pos2,
            icon: {
              fillColor: "#ffffff",
              fillOpacity: 1,
              strokeWeight: 1,
              strokeColor: "#000000",
              scale: 0.035,
            },
            map: map});

          if (polyline) {
            const flightPlanCoordinates = [
              {
                lat: endPos.lat,
                lng: endPos.lng,
              },
              {
                lat: startPos.lat,
                lng: startPos.lng,
              },
            ];
            const flightPath = new window.google.maps.Polyline({
              path: flightPlanCoordinates,
              geodesic: true,
              strokeColor: "#F57F17",
              fillColor: "#526f2f",
              strokeOpacity: 1.0,
              strokeWeight: 2,
              editable: true
            });
              vmInstance.polyline =  flightPath;
            flightPath.setMap(map);
            polylines.push(flightPath);
          }

            a.push(marker2);
          new window.google.maps.event.trigger( marker2, 'click' );
          var e = {lat: startPos.lat, lng: startPos.lng}
          new window.google.maps.event.trigger(map, 'click', e);
          var e2 = {lat: endPos.lat, lng: endPos.lng};
          new window.google.maps.event.trigger(map, 'click', e2);
        });
      }
      function DrawPolygon(vmInstance) {
        vmInstance.map.$mapPromise.then((map) => {
          vmInstance.drawingManagerTool.setMap(map);
          if (polylines.length !== 0) {
            for (let i = 0; i < polylines.length; i++) {
              polylines[i].setMap(null);
            }
          }
          // document.getElementsByClassName("gmnoprint")[0].children[1].children[0].click();
          a[3].setMap(null);
          const fieldCoords = [
            { lat: 33.911670, lng: 36.056760 },
            { lat: 33.912017, lng: 36.057752 },
            { lat: 33.911341, lng: 36.058744 },
            {lat: 33.910797, lng: 36.058036},
            {lat: 33.911670, lng: 36.056760 }
          ];
          // Construct the polygon.
          completeField = new window.google.maps.Polygon({
            paths: fieldCoords,
            strokeOpacity: 0.8,
            strokeWeight: 2,
            fillOpacity: 0.35,
            editable: true,
            strokeColor: "#F57F17",
            fillColor: "#526f2f",
          });
          completeField.setMap(map);
        });
      }

      let steps = [
        {
          title: vmInstance.$t('guide.title_1'),
          intro: vmInstance.$t('guide.step_1')
        },
        {
          title: vmInstance.$t('guide.title_3'),
          element: document.querySelector('#draw-field-button'),
          intro: vmInstance.$t('guide.step_3')
        },
        {
          title: vmInstance.$t('guide.title_4'),
          element: document.getElementById('overlay'),
          intro: vmInstance.$t('guide.step_4')
        },
        {
            intro: vmInstance.$t('guide.step_click_on_corners'),
            element: document.getElementById('IamANSVG'),
            title: vmInstance.$t('guide.step_draw_your_field')
        },
        {
          title: vmInstance.$t('guide.title_5'),
          element: document.getElementsByClassName("user-guide-button-two")[0],
          intro: vmInstance.$t('guide.step_5')
        },
        {
          title: vmInstance.$t('guide.title_6'),
          element: document.querySelector('.results-window'),
          intro: vmInstance.$t('guide.step_6')
        },
        {
          title: vmInstance.$t('guide.title_7'),
          element: document.querySelector('.results-window'),
          intro: vmInstance.$t('guide.step_7')
        },
      ];

      if (!this.user) {
        steps.splice(1, 0, {
          title: vmInstance.$t('guide.title_2'),
          element: document.querySelector('.login-methods'),
          intro: vmInstance.$t('guide.step_2')
        });
      }

      intro.setOptions({
        showStepNumbers: false,
        dontShowAgain: false,
        nextLabel: vmInstance.$t('guide.next'),
        prevLabel: vmInstance.$t('guide.previous'),
        doneLabel: vmInstance.$t('guide.done'),
        steps: steps
      });

      function delay(t, v) {
        return new Promise(function(resolve) {
            setTimeout(resolve.bind(null, v), t)
        });
      }

      intro.onbeforechange(function() {
        if (intro._currentStep == "3") {
          vmInstance.$emit('goToLocation', 33.911652, 36.057618);
          document.getElementsByClassName('introjs-overlay')[0].style.opacity = '0.5';
          document.getElementsByClassName('introjs-prevbutton')[0].classList.remove("d-none");
          document.getElementsByClassName('introjs-tooltip')[0].style.backgroundColor = "white";
        }
        if (intro._currentStep == "3") {
          intro.setOption('tooltipPosition', 'bottom');
          delay(1).then(function() {
              DrawPolyline(vmInstance, startPos1, endPos1, false);
              return delay(1000);
          }).then(function() {
              DrawPolyline(vmInstance, endPos1, endPos2, true);
              return delay(1000);
          }).then(function() {
              DrawPolyline(vmInstance, endPos3, endPos1, true);
              return delay(1000);
          }).then(function() {
              DrawPolyline(vmInstance, endPos4, endPos3, true);
          }).then(function() {
             DrawPolygon(vmInstance);
          });
          document.getElementsByClassName('user-guide-button-two')[0].classList.remove("d-none");

        }

        if (intro._currentStep == "5") {
          vmInstance.hideTabs = false;
          document.getElementsByClassName('introjs-overlay')[0].style.opacity = '0.5';

          document.getElementsByClassName('introjs-overlay')[0].classList.remove("d-none");
          document.getElementsByClassName('introjs-tooltipReferenceLayer')[0].classList.remove("d-none");

          document.getElementsByClassName("fixed-bottom")[0].classList.remove("d-none");
          document.getElementsByClassName('introjs-tooltipbuttons')[0].classList.remove("d-none");
        }
      });

      intro.onchange(function() {
        if (intro._currentStep == "5") {
          document.getElementsByClassName('introjs-tooltiptext')[0].style.height = "fit-content";
          if (document.getElementsByClassName('user-guide-button').length > 0) {
            document.getElementsByClassName('user-guide-button')[0].classList.add("d-none");
          }
          if (typeof document.getElementsByClassName('user-guide-button-two')[0] !== 'undefined') {
            document.getElementsByClassName('user-guide-button-two')[0].classList.add("d-none");
          }
          vmInstance.hideTabs = false;
        }
        if (intro._currentStep == "6") {
          document.getElementsByClassName("nav-item")[0].childNodes[0].click();
        }
        if (intro._currentStep == "7") {
          document.getElementsByClassName("nav-item")[1].childNodes[0].click();
          // document.querySelector('.introjs-nextbutton').style.display = 'none';
          document.querySelector('.introjs-tooltipbuttons').style.display = 'flex';
          document.querySelector('.introjs-tooltipbuttons').style.flexDirection = 'row-reverse';
          document.querySelector('.introjs-prevbutton').style.marginLeft = '0.25rem';
          document.querySelector('.introjs-prevbutton').style.border = "1px solid #d4d4d4";
          document.querySelector('.introjs-prevbutton').style.borderRadius = "0.2rem !important";
        }
      });

      intro.start()

      intro.onexit(function() {
        vmInstance.$emit('goToDetectedLocation');
        vmInstance.showHelpText = true;
        vmInstance.hideTabs = true;
        if (document.getElementsByClassName('user-guide-button').length > 0) {
          document.getElementsByClassName('user-guide-button')[0].classList.add("d-none");
        }
        if (polylines.length !== 0) {
          for (let i = 0; i < polylines.length; i++) {
            polylines[i].setMap(null);
          }
        }
        if (a.length !== 0) {
          for (let i = 0; i < a.length; i++) {
            a[i].setMap(null);
          }
        }
        if (completeField) {
          completeField.setMap(null);
        }
        document.getElementsByClassName("gmnoprint")[0].children[0].children[0].click();
        document.getElementsByClassName("fixed-bottom")[0].classList.add("d-none");
        document.getElementsByClassName('user-guide-button-two')[0].classList.add("d-none");

        vmInstance.$bvModal.hide('new-field-modal');
        vmInstance.$store.commit('setUserGuideMode', false);

      });
    }
  }
}
</script>

<style lang="scss" scoped>
@media (max-width: 768px) { /* adjust the value as needed */
  .mobile-logout {
    display: none;
  }
}


</style>
