import Vuex from 'vuex';
import Vue from 'vue';

Vue.use(Vuex);

import fieldStore from './stores/FieldStore';
import { irrigationSystemTypes, pipeSizes } from './constants';

export default new Vuex.Store({
  state: {
    user: null,
    fields: [],
    activeField: null,
    activePolygon: null,
    activeMarker: null,
    mapPolygons: [],
    allMarkers: [],
    editMode: false,
    draftCrop: null,
    draftIrrigationSystem: null,
    draftPipeSize: null,
    fetchingResults: false,
    result: null,
    draftField: null,
    userGuideMode: false,
    shouldIgnoreResult: false,
    irrigationWindow: '',
    loginWithMobileClicked: false,
  },
  mutations: {
    setUser(state, user) {
      state.user = user;
    },
    setFields(state, fields) {
      state.fields = fields;
    },
    setActiveField(state, field) {
      state.activeField = field;
    },
    setDraftField(state, field) {
      state.draftField = field;
    },
    setActivePolygon(state, polygon) {
      state.activePolygon = polygon;
    },

    setFetchingResults(state, fetching) {
      state.fetchingResults = fetching;
    },

    setUserGuideMode(state, status) {
      state.userGuideMode = status;
    },

    setResults(state, result) {
      state.result = result;
    },

    setLoginWithMobile(state, status) {
      state.loginWithMobileClicked = status;
    },

    setActiveMarker(state, marker) {
      state.activeMarker = marker;
    },

    setShouldIgnoreResult(state, status) {
      state.shouldIgnoreResult = status;
    },

    setMapPolygons(state, polygons) {
      state.mapPolygons = polygons;
    },

    setDraftCrop(state, crop) {
      state.draftCrop = crop;
    },
    setDraftIrrigationSystem(state, irrigationSystem) {
      state.draftIrrigationSystem = irrigationSystem;
    },
    setDraftPipeSize(state, pipeSize) {
      state.draftPipeSize = pipeSize;
    },

    setIrrigationResult(state, result) {
      state.irrigationWindow = result;
    },

    setEditMode(state, edit) {
      state.editMode = edit;
    },

    setMapMarkers(state, marker) {
      state.allMarkers.push(marker);
    },

    setAllMarkers(state, markers) {
      state.allMarkers = markers;
    },

    addMapPolygon(state, polygon) {
      state.mapPolygons.push(polygon);
    },

    addField(state, field) {
      state.fields.push(field);
    },

    removeField(state, fieldId) {
      state.fields = state.fields.filter(field => field.key !== fieldId);
    },

    clearMapPolygons(state) {
      state.mapPolygons = [];
    },

    removeMapMarker(state, marker) {
      const index = state.allMarkers.indexOf(marker);
      if (index !== -1) {
        state.allMarkers.splice(index, 1);
      }
    },

    removeMapPolygon(state, polygon) {
      const index = state.mapPolygons.indexOf(polygon);
      if (index !== -1) {
        state.mapPolygons.splice(index, 1);
      }
    }
  },

  getters: {
    getIrrigationRatio: (state) => {
      switch(irrigationSystemTypes.indexOf(state.draftIrrigationSystem || state.activeField.irrigationSystem)) {
        case 0:
          return 0.85;
        case 1:
          return 0.8;
        case 2:
          return 0.8;
        case 3:
          return 0.75;
        case 4:
          return 0.85;
        case 5:
          return 0.8;
        case 6:
          return 0.75;
        case 7:
          return 0.75;
        case 8:
          return 0.7;
        case 9:
          return 0.6;
        case 10:
          return 0.5;
      }
    },

    getPipeSizeRatio: (state) => {
      console.log('Hello world')
      switch(pipeSizes.indexOf(state.draftPipeSize || state.activeField.pipeSize)) {
        case 0:
          return 2.6;
        case 1:
          return 4.0;
        case 2:
          return 6.0;
        case 3:
          return 11.0;
        case 4:
          return 17.0;
        case 5:
          return 24.0;
        case 6:
          return 42.0;
        case 7:
          return 66.0;
        case 8:
          return 90.0;
      }
    },
  },
  actions: {
    async fetchFieldsByDevice({ commit }, email) {
      const fields = await fieldStore.fetchFieldsByDevice(email);
      commit('setFields', fields);
    },

    async deleteField({ commit }, fieldKey) {
      console.log(commit)
      await fieldStore.deleteField(fieldKey);
    },

    logout({ commit }) {
      commit('setUser', null);
      commit('setFields', null);
      commit('setMapPolygons', null);
      commit('setAllMarkers', null);
    },

    clearDraftSelection({commit}) {
      commit('setDraftCrop', null);
      commit('setDraftIrrigationSystem', null);
      commit('setDraftPipeSize', null);
    },

    async login({ commit }, user) {
      commit('setUser', user);
      console.log(user);
      const fields = await fieldStore.fetchFieldsByDevice(user.email);
      // setTimeout(() => {
      //   commit('setFields', fields);
      // }, 2000); Adjust the delay as needed

      commit('setFields', fields);
    },
  },
});
