<template>
  <b-modal centered scrollable :id="'edit-'+field.key">
    <b-container fluid>
      <FieldForm :field="field"/>
    </b-container>
    <template #modal-header="{ close }">
      <h5>{{ $t(`my_fields.edit_your_field`) }}</h5>
      <b-button ref="Close" variant="light" size="sm" @click="close()">
        X
      </b-button>
    </template>
     <template #modal-footer="{ cancel }">
         <div class="" style="width: 100%; display: flex; justify-content: space-between; flex-direction: row-reverse;">
             <div class="">
            <b-button align-v="end" class="ml-2" size="sm" variant="secondary" @click="cancel()">
              {{ $t(`global.cancel`) }}
            </b-button>
            <b-button size="sm" class="ml-2" variant="success" @click="editFormSubmitted()">
              {{ $t(`global.save`) }}
            </b-button>
             </div>
             <div class="">
                 <b-button align-v="start" size="sm" variant="success" @click="editFieldDrawing()">
                     <b-icon icon="pentagon"></b-icon>
                     {{ $t(`fields.edit`) }}
                 </b-button>
             </div>
         </div>
     </template>
  </b-modal>
</template>

<script>
import store from '../../stores/FieldStore'
import FieldForm from './FieldForm';
import { mapState } from 'vuex';

export default {
  name: 'FieldEdit',

  components: {
    FieldForm
  },

  props: {
    field: { type: Object }
  },

   methods: {
    editFormSubmitted () {
      var fieldKey = this.field.key;
      let polygon = this.mapPolygons.find(polygon => polygon.name === this.field.name);
      this.$store.commit('setActivePolygon', polygon);
      if (this.activePolygon) {
        var computedArea = window.google.maps.geometry.spherical.computeArea(this.activePolygon.getPath());
        this.field.fieldArea = computedArea;
        var polygonPath = this.activePolygon.getPath();
        var polygonCoordinates = this.getPolygonCoordinates(polygonPath);
        var fieldPosition = this.formatPolygonCoordinates(polygonCoordinates);
        this.field.position = fieldPosition;
      }

      store.editField(fieldKey, this.field);
      this.$store.commit('setActiveField', this.field);
      this.$root.$emit('bv::hide::modal', 'edit-'+this.field.key);
      this.$root.$emit('bv::hide::modal', 'field-modal');
      this.$root.$emit('goToField', this.field);
      if (this.activePolygon) {
        this.activePolygon.setEditable(false);
      }

      this.$store.commit('setActivePolygon', null);
      this.$store.commit('setActiveField', null);
      this.$store.commit('setEditMode', false);
    },

    editFieldDrawing () {
      let polygon = this.mapPolygons.find(polygon => polygon.name === this.field.name);
      this.$store.commit('setActivePolygon', polygon);
      this.$store.commit('setEditMode', true);

      this.$root.$emit('bv::hide::modal', 'edit-'+ this.field.key);
      this.$store.commit('setActiveField', this.fields.filter(field => field.key === this.field.key)[0]);
      this.$root.$emit('bv::hide::modal', 'field-modal');
      this.$emit('closeInfoWindow');
      this.$root.$emit('goToField', this.field);

      this.editPolygon();
      // this.$emit('editPolygon', this.field.key, this.field);
    },

    editPolygon () {
      this.activePolygon.setEditable(true);
      this.$store.commit('setEditMode', true);

          // Listen to the 'set_at' and 'insert_at' events on each path of the polygon
      this.activePolygon.getPaths().forEach((path) => {
        window.google.maps.event.addListener(path, 'set_at', () => {
          this.updateActivePolygon();
        });
        window.google.maps.event.addListener(path, 'insert_at', () => {
          this.updateActivePolygon();
        });
      });
    },

    getPolygonCoordinates (polygonPath) {
      console.log('here i am in getPolygonCoordinates');
      let coordinates = polygonPath.getArray().map(point => [point.lng(), point.lat()]);

      // Add the first point to the end of the array to close the polygon
      coordinates.push(coordinates[0]);
      return coordinates;
    },


    formatPolygonCoordinates (coordinates) {
      return coordinates.map(coords => `lat/lng: (${coords[1]}, ${coords[0]})`).join(',');
    },

    updateActivePolygon() {
      // Commit the updated activePolygon to the Vuex store
      this.$store.commit('setActivePolygon', this.activePolygon);
    },
  },

  computed: {
		...mapState(['activeField', 'activePolygon', 'fields', 'mapPolygons']),
	}
}
</script>
