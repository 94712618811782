<template>
  <div class="d-none">
  </div>
</template>
<script>
import settingsStore from '../stores/SettingsStore'
import { Plugins } from "@capacitor/core";
const { Device } = Plugins;

export default {
  name: 'LocaleSwitcher',

  data () {
    return {
      locales: process.env.VUE_APP_I18N_SUPPORTED_LOCALE.split(','),
      deviceId: null,
      deviceInfo: null,
      lang: null
    }
  },

  props: {
    language: { type: String, default: 'en' }
  },

  methods: {
    async getDeviceInfo () {
      this.deviceInfo = await Device.getInfo();
      this.deviceId = this.deviceInfo.uuid;
    },

    async getLanguage() {
      this.lang = await settingsStore.fetchLanguageByDeviceId(this.deviceId)
      if (!this.lang) {
        this.lang = 'en';
      }
    },

    switchLocale(language) {
      if (this.$i18n.locale !== language) {
        this.$i18n.locale = language;
      }
      if (language === 'ar') {
        document.querySelector('body').classList.toggle('rtl');
        document.querySelectorAll('.dropdown-item').forEach(item => {
            item.classList.add('rtl');
        });

      }
      if (language === 'en') {
        document.querySelector('body').classList.remove('rtl');
        document.querySelectorAll('.dropdown-item').forEach(item => {
            item.classList.remove('rtl');
        });
      }
    }
  },

  watch: {
    deviceId: function() {
      this.getLanguage();
    },

    lang: function() {
      this.switchLocale(this.lang);
    },

    language: function (newLang) {
      this.switchLocale(newLang);
    }
  },

  mounted () {
    this.$root.$on('changeLanguage', (language) => {
      this.switchLocale(language)
    })
  },

  created () {
    this.getDeviceInfo();
  }
}
</script>
