export const irrigationSystemTypes = [
    'Drip',
    'Bubbler',
    'Microsprinkler',
    'Solid-set impact sprinkler',
    'Center Pivot',
    'Linear move',
    'Hand-move sprinkler',
    'Boom sprayer',
    'Gun',
    'Surface-Basin',
    'Surface-Furrow'
  ];

export const pipeSizes = [
  "32 mm, 1 inch",
  "40 mm, 1.25 inch",
  "50 mm, 1.5 inch",
  "63 mm, 2 inch",
  "75 mm, 2.5 inch",
  "90 mm, 3 inch",
  "110 mm, 4 inch",
  "140 mm, 5 inch",
  "160 mm, 6 inch"
]
