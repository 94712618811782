import firebase from 'firebase/app';
import 'firebase/database';
import 'firebase/auth';

// firebase init - add your own config here
const firebaseConfig = {
  apiKey: 'AIzaSyDD-nsNjZR2rDwJ4ys9yA-FAC22peIDsWQ',
  authDomain: 'agsat.app',
  databaseURL: 'https://wired-ripsaw-240811.firebaseio.com',
  projectId: 'wired-ripsaw-240811',
  storageBucket: 'wired-ripsaw-240811.appspot.com',
  messagingSenderId: '947426181099',
  appId: '1:947426181099:web:2a74b09745494c1cd063ae',
  measurementId: "G-DJXRB955R3"
}
// Staging
// const firebaseConfig = {
//   apiKey: "AIzaSyDD-nsNjZR2rDwJ4ys9yA-FAC22peIDsWQ",
//   authDomain: "wired-ripsaw-240811.firebaseapp.com",
//   databaseURL: "https://wired-ripsaw-240811-3bf8c.firebaseio.com",
//   projectId: "wired-ripsaw-240811",
//   storageBucket: "wired-ripsaw-240811.appspot.com",
//   messagingSenderId: "947426181099",
//   appId: "1:947426181099:web:78873a8d7d0866cad063ae",
//   measurementId: "G-Z9BCPQK2RL"
// };

var fb = firebase.initializeApp(firebaseConfig);

// utils
const db = fb.database();
const auth = fb.auth();

// collection references
const usersCollection = db.ref('users');
const fieldsCollection = db.ref('fields');
const unsavedFieldsCollection = db.ref('unsavedFields');
const clicksCollection = db.ref('clicks');
const historyCollection = db.ref('history');
const settingsCollection = db.ref('settings');


// function gmailLogin() {
//   // auth.onAuthStateChanged(user => {
//   //   if (user) {
//   //     // User is signed in.
//   //     console.log(this)
//   //     this.user = user;
//   //       this.fields = fieldStore.fetchFieldsByDevice(user.email);
//   //       console.log(this.fields)
//   //     console.log('User is signed in:', user);
//   //   } else {
//   //     // No user is signed in.
//   //     console.log('No user is signed in.');
//   //   }
//   // });

//   const googleAuthProvider = new firebase.auth.GoogleAuthProvider();
//   auth.signInWithPopup(googleAuthProvider)
//   .then(async function(result) {
//     // userStore.user = result.user;
//     userStore.commit('setUser', result.user);
//     // userStore.commit('setFields', fieldStore.fetchFieldsByDevice(result.user.email));
//     const fields = await fieldStore.fetchFieldsByDevice(result.user.email);
//     userStore.commit('setFields', fields);
//   });
// }

function gmailLogout() {
  auth.signOut();
}

// export utils/refs
export {
  fb,
  db,
  auth,
  gmailLogout,
  usersCollection,
  fieldsCollection,
  unsavedFieldsCollection,
  clicksCollection,
  historyCollection,
  settingsCollection,
  firebase
}
