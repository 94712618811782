<template>

<!-- ************************** START MAIN NAVBAR ************************** -->

    <b-navbar sticky toggleable="sm" type="light" variant="light">

<!-- - - - - - - - - - - - - - - - NAVBAR BRAND  - - - - - - - - - - - - - - -->
<b-navbar-nav class="mr-auto">
      <a href="https://agsat.app" class="no-underline">
        <b-navbar-brand class="d-flex">
          <div class="agsat-logo">
            <b-img  :src="require(`../../assets/agsat.png`)"></b-img>
          </div>
          <div class="my-auto pl-2 h2 text-secondary font-weight-light">
            AgSAT
          </div>
        </b-navbar-brand>
      </a>
    </b-navbar-nav>
<!-- - - - - - - - - - - - - - - NAVBAR SEARCH - - - - - - - - - - - - - - - -->
      <div class="test" style="display: flex; flex-wrap: wrap;">
        <b-navbar-nav class="ml-auto">
          <b-nav-form @submit="submitSearch()">
            <div
              :class="mobileViewPort ? `collapse` : ``"
              id="placeSearch">
              <b-input-group>
                <b-form-input
                  type="search"
                  id="place-search"
                  :placeholder="$t(`global.search_location`)"
                  v-on:change="submitSearch()"
                  v-model="place"
                ></b-form-input>
                <b-form-input hidden class="d-none">
                </b-form-input>
              </b-input-group>
            </div>
            <b-button variant="outline-secondary"
                style="margin-right: 0.5rem;" >
              <b-icon
                data-toggle="collapse"
                data-target="#placeSearch"
                aria-expanded="false"
                aria-controls="placeSearch"
                :title="$t(`global.search_location`)" icon="search">
              </b-icon>
            </b-button>
          </b-nav-form>
        </b-navbar-nav>

  <!-- - - - - - - - - - - NAVBAR FIELDS ANS SETTINGS - - - - - - - - - - - -  -->
        <b-navbar-nav class="custom-settings">
          <b-button
            v-if="user" v-b-modal.field-modal href="#"
            v-b-tooltip.hover :title="$t(`my_fields.my_fields`)"
            size="md" style="color: black" variant="outline-secondary" :style="mobileViewPort ? '' : 'margin-left: 0.5rem;'">
            <font-awesome-icon icon="layer-group" />
          </b-button>
          <b-form style="margin-left: 0.5rem;">
          <b-form-select
            id="input-language"
            v-model="language"
            :options="lang"
            value-field="value"
            text-field="text"
            style="height: 2.5rem;"
            @change="changeLanguage()"
            required>
          </b-form-select>
          </b-form>
            <b-button
              v-if="user && user.email"
              style="margin-right: 0.25rem; margin-left: 0.25rem"
              v-b-modal.contact-us variant="outline-secondary">
              <b-icon
                data-toggle="collapse"
                :title="$t(`global.contact_us`)"
                icon="question-circle">
              </b-icon>
            </b-button>
        </b-navbar-nav>
        <div class="dropdown">
          <b-button
            type="button"
            data-toggle="dropdown" aria-haspopup="true"
            id="dropdownMenuButton1" style="color: black;padding: 0px;" variant="outline-secondary" aria-expanded="false">
            <b-icon icon="three-dots-vertical" class="dots-icon"></b-icon>
          </b-button>
            <LocaleSwitcher />
          <ul
            :class="this.$i18n.locale === 'en' ? `dropdown-menu-right` : `text-right`"
            class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
            <li>
              <b-button class="dropdown-item" type="button"
              v-b-modal.about-modal href="#">

                {{ $t(`global.about`) }}
              </b-button>
            </li>
            <li>
              <b-button class="dropdown-item" type="button"
                v-b-modal.share-modal href="#">

                {{ $t(`global.recommend`) }}
              </b-button>
            </li>
          </ul>
        </div>
        <CustomSettings />
        <About />
        <ContactUs :user="user"/>
      </div>
    </b-navbar>
<!-- ************************ END MAIN NAVBAR ****************************** -->
</template>

<script>
import LocaleSwitcher from '../LocaleSwitcher.vue';
import settingsStore from '../../stores/SettingsStore';
import CustomSettings from '../Settings/CustomSetting';
import About from '../Settings/About';
import ContactUs from './ContactUsModal';
import { Plugins } from "@capacitor/core";
// import { Capacitor } from '@capacitor/core';
const { Device, Keyboard } = Plugins;

export default {
  name: "Header",

  components: {
    LocaleSwitcher,
    CustomSettings,
    About,
    ContactUs
  },

  props: {
    user: { type: Object, default: null},
    mobileViewPort: { type: Boolean, default: false},
    a: { type: Function, default: () => {} },
  },

  data () {
    return {
      place: null,
      deviceId: null,
      deviceInfo: null,
      language: null,
      firstLoad: true
    }
  },

  computed: {
      lang () {
          var fullText = [
              { value: 'en', text: this.$i18n.t(`global.english`) },
              { value: 'ar', text: this.$i18n.t(`global.arabic`) },
          ];

          var abbreviatedText = [
              { value: 'en', text: this.$i18n.t(`global.abbr_english`) },
              { value: 'ar', text: this.$i18n.t(`global.abbr_arabic`) },
          ];

          if (this.mobileViewPort) {
              return abbreviatedText;
          } else {
              return fullText;
          }
      }

  },

  watch: {
    deviceId: function() {
      this.getLanguage();
    }
  },

  created () {
    this.getDeviceInfo();
    Keyboard.setAccessoryBarVisible({ isVisible: false });
      if (this.firstLoad) {
          this.language = (navigator.language.includes("en") ? "en" : "ar");
          this.firstLoad = false;
      } else {
          this.getLanguage();
      }
  },

  methods: {
    submitSearch ( ) {
      var request = {
        query: this.place,
        fields: ['name', 'geometry'],
      };

      if (this.a.$refs.googleMap) {
        this.a.$refs.googleMap.$mapPromise.then((map) => {
          var service = new window.google.maps.places.PlacesService(map);
          console.log('here')
          service.findPlaceFromQuery(request, function(results, status) {
            if (status === window.google.maps.places.PlacesServiceStatus.OK) {
              for (var i = 0; i < results.length; i++) {
                new window.google.maps.Marker({
                  position: { lat: results[0].geometry.location.lat(), lng: results[0].geometry.location.lng() },
                  map: map
                });
              }
              map.setCenter(results[0].geometry.location);
              document.getElementById('place-search').value =  "";
            }
          });
        });
      }
    },

    async getDeviceInfo() {
      this.deviceInfo = await Device.getInfo();
      this.deviceId = this.deviceInfo.uuid;
    },

    async getLanguage() {

            this.language = await settingsStore.fetchLanguageByDeviceId(this.deviceId);
            this.language ||= 'en';
            localStorage.language = this.language;
    },

    async changeLanguage () {
      settingsStore.saveLanguageByDeviceId(this.deviceId, this.language);
      this.$root.$emit('changeLanguage', this.language);
    }
  }
};
</script>
<style lang="scss" scoped>
.no-underline:hover {
  text-decoration: none;
}
@media (max-width: 768px) {
  .navbar-nav {
    padding: 0
  }

  .dots-icon {
    margin: 0.4rem;
  }

  .custom-settings {
    display: flex;
    flex-direction: row;
  }

  .navbar-brand {
    .h2 {
      font-size: 1.5rem;
    }
  }
};
</style>
