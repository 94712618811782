<template>
	<b-modal centered id="login-required-modal" >
		<p class="my-4">{{ $t(`global.login_required` )}}</p>
		<template #modal-header="{ close }">
			<h5>{{$t(`global.login_prompt`)}}</h5>
			<b-button ref="Close" size="sm" variant="light" @click="close()">
			X
			</b-button>
      </template>
		<template  #modal-footer="{ ok, cancel }">
			<b-button size="md" @click="cancel()">{{ $t(`global.cancel`) }}</b-button>
			<b-button size="md" variant="primary" @click="ok()">{{ $t(`global.ok`) }}</b-button>
		</template>
	</b-modal>
</template>
<script>
export default {
  name: 'LoginRequiredModal'
}
</script>
