<template>
  <b-modal id="mobile-number-modal" @hide="onModalHide">
    <template #modal-title>
      Enter your mobile number
    </template>

    <b-form v-if="!showOtpInput">
      <b-form-group>
        <b-form-input id="input-1" v-model="phoneNumber" type="tel" required placeholder="Enter your mobile number (+96171345678)"></b-form-input>
      </b-form-group>
      <b-button @click="emitPhoneNumber" variant="primary">Next</b-button>
    </b-form>
    <b-form v-else>
      <b-form-group>
        <b-form-input id="input-2" v-model="otp" type="text" required placeholder="Enter OTP"></b-form-input>
      </b-form-group>
      <b-button @click="verifyOtp" variant="primary">Verify</b-button>
    </b-form>
  </b-modal>
</template>
<script>
import { mapState } from 'vuex';
export default {
  name: 'MobileNumberModal',
  data() {
    return {
      phoneNumber: '',
      otp: '',
      showOtpInput: false
    };
  },

  computed: {
    ...mapState(['loginWithMobileClicked']),
  },
  methods: {
    emitPhoneNumber() {
      this.$emit('submitPhoneNumber', this.phoneNumber);
      this.showOtpInput = true;
    },
    verifyOtp() {
      this.$emit('submitOtp', this.otp);
    },

    onModalHide() {
      this.$store.commit('setLoginWithMobile', false);
    }
  }
}
</script>
