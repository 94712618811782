<template>
  <div>
    <b-modal ref="fieldModal" id="field-modal" centered hide-footer scrollable>
      <b-input-group>
        <input class="form-control form-check-input ml-0" v-model="searchTerm" type="text" style="border: 1px solid #CCCCCC; border-radius: 0.25rem 0 0 0.25rem; width: 80%; min-height: 2rem">
        <b-input-group-append>
          <b-input-group-text style="margin-top: 0.3rem">
            <b-icon icon="x" @click="searchTerm = ''"/>
          </b-input-group-text>
        </b-input-group-append>
      </b-input-group>
      <b-container fluid v-if="items.length">
        <b-table
        :fields="fields"
        :items="items"
        :busy="isBusy"
        :small="mobileViewPort"
        class="mt-3"
        outlined>
          <template v-slot:cell(actions)="row" id="hello">
            <FieldEdit :field="getField(row.item.id)" v-on:updateFields="handleUpdateFields"/>
            <div class="d-flex" v-if="mobileViewPort">
              <b-button size="sm" class="mr-1" variant="primary" @click="showEditModal(row.item.id)">
                <b-icon icon="pencil"></b-icon>
              </b-button>
              <b-button size="sm" class="mr-1" variant="danger" @click="onDeleteField(row.item.id)">
                <b-icon icon="trash-fill"></b-icon>
              </b-button>
              <div style="width: 2rem;padding-top: 0.15rem;padding-left: 0.45rem;cursor: pointer;border: 1px solid #CCCCCC;border-radius: 0.25rem;" @click="goToField(row.item.id)">
                <b-icon icon="eye-fill"/>
              </div>
            </div>
            <div class="d-flex" v-else>
              <b-button size="sm" class="mr-1" variant="primary" @click="showEditModal(row.item.id)">
                    {{$t(`global.edit`)}}
              </b-button>
              <b-button size="sm" class="mr-1" variant="danger" @click="onDeleteField(row.item.id)">
                {{$t(`global.delete` )}}
              </b-button>
              <div style="width: 2rem;padding-top: 0.15rem;padding-left: 0.45rem;cursor: pointer;border: 1px solid #CCCCCC;border-radius: 0.25rem;" @click="goToField(row.item.id)">
                <b-icon icon="eye-fill"/>
              </div>
            </div>
          </template>
          <template #table-busy>
            <div class="text-center text-danger my-2">
              <b-spinner class="align-middle"></b-spinner>
              <strong>Loading...</strong>
            </div>
          </template>
        </b-table>
      </b-container>
      <h5 v-else>
        {{ $t(`my_fields.no_fields`) }}
      </h5>
        <template #modal-header=" { close }">
          <h5>{{ $t(`my_fields.my_fields`) }}</h5>
          <b-button variant="light" size="sm" id="hhhhhhh" @click="close()">
            X
          </b-button>
        </template>
    </b-modal>
  </div>
</template>

<script>
import FieldEdit from './FieldEdit';
import { mapState } from 'vuex';

export default {
  name: 'indexField',

  components: {
    FieldEdit
  },

  props: {
    currentFields: { type: Array },
    mobileViewPort: { type: Boolean, default: false}
  },

  data() {
    return {
      searchTerm: '',
      isBusy: false,
      windowWidth: window.innerWidth,
    }
  },

  computed: {
    ...mapState(['activeField', 'activePolygon', 'fields', 'mapPolygons']),

    fields () {
      const lang = this.$i18n.locale;
       if (!lang) { return [] }
      return [
        {
          key: 'field_name',
          label: this.$i18n.t(`my_fields.field_name`)
        },
        {
          key: 'crop_type',
          label: this.$i18n.t(`my_fields.crop_type`)
        },
        {
          key: 'actions',
          label: this.$i18n.t(`my_fields.actions`)
        }
      ];
    },

    items () {
      if (!Array.isArray(this.currentFields)) {
        return [];
      }
      return this.currentFields
          .filter(field =>
            (field.name && field.name.includes(this.searchTerm)) ||
            (field.cropType && field.cropType.includes(this.searchTerm))
        )
        .map(field => ({
            field_name: field.name,
            crop_type: field.cropType,
            id: field.key
        }));
    },
  },

  methods: {
    handleUpdateFields() {
      this.$emit('updateFields');
    },

    getField (fieldId) {
      return this.currentFields.filter(field => field.key === fieldId)[0];
    },

    goToField (fieldId) {
      this.$root.$emit('bv::hide::modal', 'field-modal');
      this.$emit('goToField', this.getField(fieldId));
    },

    showEditModal(key) {
      this.$store.commit('setActiveField', this.getField(key));
      this.$bvModal.show('edit-'+key);
    },

    onDeleteField(fieldId) {
      this.$emit('delete-field', fieldId);
    },

    emitToParent (fieldKey) {
      this.$root.$emit('bv::hide::modal', 'field-modal');
      this.$emit('childToParent', fieldKey);
    },
  },

  created () {
     window.addEventListener("resize", () => {
      this.windowWidth = window.innerWidth;
    });
  }
};
</script>
