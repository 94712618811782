
import * as firebase from '../firebase'

class HistoryStore {
  async saveTodayEtValue(fieldKey, etValue, deviceId, todayDate) {
    if (firebase.auth.currentUser) {
      var params = {};
      params['EtValue'] = etValue;
      params['deviceID'] = deviceId;
      await firebase.historyCollection.child("/" + fieldKey).child(todayDate).set(params);
    }
  }

  fetchHistoryValuesByField(fieldKey) {
    let historyArray = [];
    if (fieldKey) {
        var query = firebase.historyCollection.orderByKey().equalTo(fieldKey);
        query.on('value', function(snapshot) {
            snapshot.forEach(function(childSnapshot) {
                var fieldKey = {key: childSnapshot.key};
                historyArray.push(Object.assign(fieldKey, childSnapshot.val()));
            });
        });
    }
    return historyArray;
  }
}
export default new HistoryStore
