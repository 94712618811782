import  Vue from 'vue';
import 'bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import { BootstrapVue, BootstrapVueIcons } from 'bootstrap-vue';
import App from './App.vue';
import router from './router';
import './assets/styles/custom.scss';
import VueApexCharts from 'vue-apexcharts';
import * as VueGoogleMaps from 'vue2-google-maps';
import Vue2TouchEvents from 'vue2-touch-events';
import store from './store';
import i18n from './i18n';
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { fab } from '@fortawesome/free-brands-svg-icons';
import VueSocialSharing from 'vue-social-sharing';
import VueIntro from 'vue-introjs';

import './assets/styles/custom.scss';

library.add(fas, fab);

Vue.config.productionTip = false
Vue.use(BootstrapVue);
Vue.use(BootstrapVueIcons);
Vue.use(Vue2TouchEvents);
Vue.use(VueApexCharts);
Vue.use(VueIntro);
import 'intro.js/introjs.css';
Vue.use(VueSocialSharing);
Vue.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyAcJGfXUCSJnOXen7W10yWkS7VFcNna5fQ',
    libraries: 'places,drawing',
  }
});
Vue.component('apexchart', VueApexCharts);
Vue.component('font-awesome-icon', FontAwesomeIcon);

new Vue({
  router,
  store,
  components: { App },
  i18n,
  render: h => h(App),
}).$mount('#app')
