<template>
  <div class="irrigation-models">
    <b-modal scrollable @ok="polygonTool" @cancel="clearField" id="pipe-size-modal" :style="{'margin-top': `${filterSectionHeight}rem !important`}">
      <div class="my-4 form-check" v-for="(pipeSize, index) in pipeSizes" :key="index">
        <input class="form-check-input" v-model="selectedPipeSize" type="radio" :name="pipeSize" :id="pipeSize" :value="pipeSize">
        <label class="form-check-label" :name="pipeSize" :for="pipeSize">
          <div v-if="$te(`pipes.${pipeSize}`)">
            {{ $t(`pipes.${pipeSize}`) }}
          </div>
          <div v-else>
            {{ $t(`${pipeSize}`) }}
          </div>
        </label>
      </div>
      <template #modal-header="{ close }">
        <h5>{{$t(`pipes.pipes_title`)}}</h5>
        <b-button ref="Close" size="sm" variant="light" @click="close()">
          X
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>

export default {
  name: "PipeSizeModal",

  data () {
    return {
      selectedPipeSize: '',
      disabledDraw: true,
      filterSectionHeight: 0,
      pipeSizes:[
        "32 mm, 1 inch",
        "40 mm, 1.25 inch",
        "50 mm, 1.5 inch",
        "63 mm, 2 inch",
        "75 mm, 2.5 inch",
        "90 mm, 3 inch",
        "110 mm, 4 inch",
        "140 mm, 5 inch",
        "160 mm, 6 inch"
      ],
      arabicPipeSizes:[
        "32 مم, 1 إنش",
        "40 مم, 1.25 إنش",
        "50 مم, 1.5 إنش",
        "63 مم, 2 إنش",
        "75 مم, 2.5 إنش",
        "90 مم, 3 إنش",
        "110 مم, 4 إنش",
        "140 مم, 5 إنش",
        "160 مم, 6 إنش"
      ],
    }
  },

  props: {
    mobileViewPort: { type: Boolean, default: false }
  },

  mounted () {
    if (this.mobileViewport) {
      this.filterSectionHeight = (this.windowHeight/100)*0.4;
    }
  },

  watch: {
    "selectedPipeSize": function (val) {
      if (val) {
        this.disabledDraw = false
      }
    },
  },

  methods: {
    polygonTool () {
      if (this.selectedPipeSize) {
        this.$refs.Close.click();
        this.$store.commit('setDraftPipeSize', this.selectedPipeSize);
        this.$emit('startDrawing');
      }
    },

    clearField () {
      this.$refs.Close.click();
      this.$emit('clearDraftSelection');
    }
  }
}
</script>
