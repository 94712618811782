import Vue from 'vue'
import VueRouter from 'vue-router'
import Dashboard from '../components/Landing/Dashboard';
import Dashboardold from '../components/Landing/Dashboardold';
// import FieldIndex from '../components/Field/FieldIndex';
// import FieldEdit from '../components/Field/FieldEdit';
import FieldNew from '../components/Field/FieldNew';
import About from '../components/Settings/About';
import CustomSetting from '../components/Settings/CustomSetting';
import PrivacyPolicy from '@/components/PrivacyPolicy';

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Dashboard',
    component: Dashboard,
    props: true
  },
  {
    path: '/new',
    name: 'Dashboardold',
    component: Dashboardold,
    props: true
  },
  {
    path: '/privacy-policy',
    name: 'PrivacyPolicy',
    component: PrivacyPolicy,
  },
  // {
  //   path: '/fields',
  //   name: 'Index Field',
  //   component: FieldIndex
  // },
  // {
  //   path: '/fields/:id/edit',
  //   name: 'Edit Field',
  //   component: FieldEdit
  // },
  {
    path: '/fields/new',
    name: 'New Field',
    component: FieldNew
  },
  {
    path: '/about',
    name: 'About Page',
    component: About
  },
  {
    path: '/settings',
    name: 'Custom Settings',
    component: CustomSetting
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
