<template>
  <b-modal scrollable id="realisation-modal" hide-footer>
    <div class="realisation" style="text-align: center">
      <p>
        <b>{{ $t(`realisation.realisation`) }}</b>
      </p>
      <img style="width: 12rem; margin-top: -1rem;" alt="AgSAT" src="../../assets/agsat-logo.jpg">
    </div>
    <div class="partners" style="text-align: center">
      <p>
        <b>{{ $t(`realisation.our_partners`) }}</b>
      </p>
      <a href="http://www.esa.int/" target="_blank">
        <img
          style="width: 9rem;height: 7rem;"
          alt="European Space Agency"
          src="../../assets/esa-logo.png"
        >
      </a>
      <a href="http://ldas.gsfc.nasa.gov/" target="_blank">
        <img style="width: 9rem;height: 7rem;" alt="Nasa" src="../../assets/nasa-logo.png">
      </a>
      <a href="https://www.usgs.gov/" target="_blank">
        <img style="width: 9rem;" alt="USGS" src="../../assets/usgs-logo.png">
      </a>
      <a href="http://www.un-ihe.org/" target="_blank">
        <img style="width: 15rem; height: 6rem" alt="IHE" src="../../assets/ihe-logo.png">
      </a>
      <a href="https://www.google.org/" target="_blank">
        <img style="width: 12rem;" alt="Google Org" src="../../assets/google-logo.png">
      </a>
      <a href="https://www.government.nl/" target="_blank">
        <img
          style="width: 22rem; margin-left: -9rem; margin-bottom: -2rem;"
          alt="Ministry Of Netherlands"
          src="../../assets/ministry_of_netherlands.png"
        >
      </a>
      <a href="https://www.tides.org/" target="_blank">
        <img
          style="width: 12rem;margin-right: 2rem"
          alt="Tides Foundation"
          src="../../assets/tides-logo.svg"
        >
      </a>
      <a href="https://www.aub.edu.lb/" target="_blank" style="margin-left: 5rem;">
        <img
          style="width: 9rem; margin-top: 1rem; margin-left: -3rem;"
          alt="AUB"
          src="../../assets/aub-logo.png"
        >
      </a>
      <a href="https://www.aub.edu.lb/fafs/pages/default.aspx" target="_blank">
        <img
          style="width: 5rem; margin-left: 3rem; margin-right: 2rem;"
          alt="FAFS @ AUB"
          src="../../assets/fafs-logo.jpeg"
        >
      </a>
      <br>
      <img
        style="width: 12rem;margin-top: 1.5rem"
        alt="Google"
        src="../../assets/powered-by-google.png"
      >
    </div>
    <template #modal-header="{ close }">
      <h5>AgSAT</h5>
      <b-button variant="light" size="sm" @click="close()">
        X
      </b-button>
    </template>
  </b-modal>
</template>

<script>
export default {
  name: "Realisation"
};
</script>
