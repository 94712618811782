<template>
  <b-modal scrollable centered id="about-modal" hide-footer>
    <template #modal-header="{ close }">
      <h5>{{ $t(`global.about`) }}</h5>
      <b-button variant="light" size="sm" @click="close()">
        X
      </b-button>
    </template>
    <div class="about-page">
      <div class="about-body px-i px-y">
        <h6>
          {{$t('about.application_utilisation')}}
        </h6>
        <br/>
        <h5>
          {{$t('about.disclaimer')}}
        </h5>
        <h6>
          {{$t('about.app_usage')}}
          <br/>

          {{$t('about.app_data')}}
          <br/>
          <br/>
          {{$t('about.copy')}}
          <br/>
          <br/>
          smartirrigation2020@gmail.com
          <br/>
          <br/>
          {{$t('about.data_from')}}
          <br/>
          <br/>
          <div class="logo">
            <img src="../../assets/nasa-logo.png" class="sources-logo"/>
            <img src="../../assets/esa-logo.png" class="sources-logo"/>
            <img src="../../assets/noaa-logo.png" class="sources-logo"/>
            <br/>
            <img src="../../assets/powered-by-google.png" id="google-image"/>
          </div>
        </h6>
      </div>
    </div>
  </b-modal>
</template>

<script>
import {Capacitor} from "@capacitor/core";

export default {
  name: 'AboutPage',

  data () {
    return {
      windowWidth: window.innerWidth,
      windowHeight: window.innerHeight,
        filterSectionHeight: 0.5,
    }
  },

  computed: {
    mobileViewport () {
      return this.windowWidth < 768;
    }
  },

    mounted () {
        if (this.mobileViewport && Capacitor.isNative) {
            this.filterSectionHeight = (this.windowHeight/100)*0.4;
        }
    },
}
</script>

<style lan="scss">
@media (max-width: 768px) {
    .mobile-navbar {
        background-color: #EAEAEA !important;
        padding: 3rem 0.5rem 0.5rem 0.65rem
    }

    .logo {
        width: 100%;
    }
    .modal-dialog {
        padding-top: 3rem !important;
    }
}
</style>

