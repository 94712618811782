
import * as firebase from '../firebase'

class SettingsStore {
  async saveLanguageByDeviceId(deviceId, language) {
      await firebase.settingsCollection.child("/" + deviceId).child('language').set(language);
  }

  async fetchLanguageByDeviceId(deviceId) {
    let language = null;
    if (deviceId) {
      await firebase.settingsCollection.child("/" + deviceId).once('value').then((snapshot) => {
        language = (snapshot.val() && snapshot.val().language);
      });
      return language
    }
  }
}
export default new SettingsStore
