<template>
	<div class="my-2 d-flex" style="z-index: 1000;">
		<!--BUTTONS USED IN USER GUIDE -->
		<div class="card-demo" style="height: fit-content">
			<!-- SAVE FIELD USER GUIDE BUTTON -->
			<b-button id="save-field-button" variant="primary" pill size="md" class="mx-1 mt-1 user-guide-button-two d-none" >
				{{ $t(`global.save`)}}
			</b-button>
		</div>
		<!-- ACTUAL USED BUTTONS USED TO DRAW, SAVE (OR EDIT), DELETE -->
		<div style="z-index: 1000;" class="d-flex my-1">
			<!-- DRAW FIELD BUTTON THAT IS SHOWN ONLY IF SHOW DRAW BUTTON IS TRUE -->
			<b-button @click="showCropTypeModal()" v-if="showDrawButton" id="draw-field-button" variant="primary" pill size="md" class="agsat-btn mobile-button d-flex mx-1" >
				<b-icon :class="{ 'mr-1': !(!user || !mobileViewPort) }" icon="pentagon"></b-icon>
        <span :class="{ 'mb-0': true, 'button-text': (!user || !mobileViewPort) }">{{ $t(`dashboard.${drawButtonText}`) }}</span>
      </b-button>
			<!-- SAVE FIELD BUTTON THAT IS SHOWN ONLY IF SHOW SAVE BUTTON IS TRUE -->
			<b-button v-if="showSaveButton" :class="mobileButton"
        @click="updateField(activeField.key)" v-b-modal.new-field-modal pill class="agsat-btn  d-flex mx-1 mobile-button" variant="primary">
        <b-icon icon="pencil"></b-icon>
        <p class="mb-0 button-text" style="margin-left: 0.5rem"> {{ $t(`global.save`)}}</p>
      </b-button>
      <b-button v-if="showEditButton" :class="mobileButton" @click="editField" pill class="agsat-btn  d-flex mx-1 mobile-button" variant="primary">
        <b-icon icon="pencil"></b-icon>
        <p class="mb-0 button-text" style="margin-left: 0.5rem"> {{ $t(`global.edit`)}}</p>
      </b-button>
			<!-- DELETE FIELD BUTTON THAT IS SHOWN ONLY IF SHOW DELETE BUTTON IS TRUE -->
			<b-button v-if="showDeleteButton" :class="mobileButton"
        @click="deleteField(activeField.key)"
        class="agsat-btn  d-flex mx-1 mobile-button" pill variant="danger">
				<b-icon icon="trash"></b-icon>
        <p class="mb-0 button-text" style="margin-left: 0.5rem">{{ $t(`global.delete` )}}</p>
      </b-button>
      <b-button v-if="showClearButton" :class="mobileButton"
        @click="clearSelection"
        class="agsat-btn  d-flex mx-1 mobile-button" id="clear-selection" pill variant="danger">
				<b-icon icon="x-circle"></b-icon>
        <p class="mb-0 button-text" style="margin-left: 0.5rem">{{ $t(`global.clear` )}}</p>
      </b-button>
			<!-- SHOW ALL USERS IN SIDEBAR FOR ADMIN USER, ADMIN USER WHICH IF THE CURRENT USER IS AN ADMIN OR NOT -->
      <b-button v-if="adminUser" v-b-toggle.all-user-sidebar>
        Toggle All Users Sidebar
      </b-button>
		</div>
	</div>
</template>
<script>
import { mapState } from 'vuex';

export default {
  name: 'ButtonsToolbar',

	data () {
    return {
			drawButtonText: "draw_field"
		}
	},

	props: {
		field: { type: Object },
		mobileViewPort: { type: Boolean },
    draftField: { type: Object, default: null },
	},

  mounted() {
    document.addEventListener('keydown', this.onKeyDown);
  },

  beforeDestroy() {
    document.removeEventListener('keydown', this.onKeyDown);
  },

  computed: {
		...mapState(['user', 'fields',
    'activeField', 'activePolygon',
     'activeMarker', 'editMode',
    'draftCrop', 'draftIrriSystem', 'draftPipeSize']),

		showDrawButton () {
			if (this.activeField || this.activePolygon) {
				return false;
			} else {
				return true;
			}
		},

    showSaveButton () {
			if (this.activeField || this.editMode) {
				return false;
			} else if ((this.activePolygon || this.draftField) && !this.editMode) {
				return true;
			} else {
        return false;
      }
		},

		showEditButton () {
			if (this.activeField || this.editMode) {
				return true;
			} else {
				return false;
			}
		},

		showDeleteButton () {
			if (this.activeField) {
				return true;
			} else {
				return false;
			}
		},

    showClearButton () {
      if (this.activePolygon || this.draftField) {
        return true;
      } else {
        return false;
      }
    },

		mobileButton () {
			if (this.mobileViewPort) {
        return 'mobile-draw-button';
      } else {
        return '';
      }
		},

		adminUser () {
			return false
		}
	},

  methods: {
    showCropTypeModal () {
      if (this.user) {
        this.$store.commit('setShouldIgnoreResult', false);
        this.$bvModal.show('crop-type-modal');
      } else {
        this.$emit('showLoginModal');
      }
    },

    editField () {
      this.$bvModal.show('edit-'+this.activeField.key);
    },

    onKeyDown(event) {
      if (event.key === 'Escape') {
        this.$emit('closeInfoWindow');
        this.$store.commit('setFetchingResults', false);
        this.$store.commit('setActivePolygon', null);
        this.$store.commit('setActiveField', null);
        this.$store.commit('setActiveMarker', null);
      }
    },

    deleteField(fieldKey) {
      this.showConfirmDeleteModal(fieldKey);
    },

    showConfirmDeleteModal (fieldId) {
      this.$bvModal.msgBoxConfirm(this.$t('my_fields.are_you_sure_you_want_to_delete'), {
        title: this.$t('my_fields.confirm_deletion'),
        size: 'sm',
        buttonSize: 'sm',
        okVariant: 'danger',
        okTitle: this.$t('my_fields.yes'),
        cancelTitle: this.$t('my_fields.no'),
        footerClass: 'p-2',
        hideHeaderClose: false,
        centered: true
      }).then(value => {
          if (value) {
            var fieldName = this.getFieldName(fieldId);
            this.$bvToast.toast(`${fieldName} Field deleted`, {
              title: 'Success',
              variant: 'success',
              solid: true,
              toaster: 'b-toaster-bottom-right',
            });
            var fieldKey = fieldId || this.activeField.key;
            this.$store.commit('setFetchingResults', false);
            if (this.activePolygon) {
              this.activePolygon.setMap(null);
            }
            if (this.activeMarker) {
              this.activeMarker.setMap(null);
            }
            this.$store.dispatch('deleteField', fieldKey);
            this.$store.commit('removeField', fieldKey);
            this.clearSelection();
          } else {
            this.$bvToast.toast('Delete cancelled', {
              title: 'Cancelled',
              variant: 'danger',
              solid: true
            });
          }
        })
        .catch(err => {
          // An error occurred
          console.log(err)
        });
    },

    updateField(fieldKey) {
      if (fieldKey) {
        var computedArea = window.google.maps.geometry.spherical.computeArea(this.activePolygon.getPath());
        this.field.fieldArea = computedArea;
        this.$store.commit('setActiveField', this.field);
        this.$bvModal.show('edit-'+fieldKey);
      } else {
        this.$bvModal.show('new-field-modal');
      }
    },

    getFieldName(id) {
      let field;
      if (id) {
        field = this.fields.find(field => field.key === id);
      } else {
        field = this.activeField
      }
      return field.name;
    },

    clearSelection () {
      this.activePolygon.setEditable(false);
      if (!this.activePolygon.saved) {
        this.activePolygon.setMap(null);
        this.activeMarker.setMap(null);
      }
      this.$emit('closeInfoWindow');
      this.$store.commit('setEditMode', false);
      this.$store.commit('setActivePolygon', null);
      this.$store.commit('setDraftField', null);
      this.$store.commit('setActiveField', null);
      this.$store.commit('setActiveMarker', null);
      this.$store.commit('setFetchingResults', false);
      this.$store.commit('setResults', null);
      this.$store.commit('setShouldIgnoreResult', true);
    }
  }
}
</script>

<style lang="scss" scoped>
.agsat-btn {
  height: fit-content;
}
@media (max-width: 768px) { /* adjust the value as needed */
  .button-text {
    display: none;
  }
  .mobile-button {
    display: flex;
    align-items: center;
    justify-content: center;
    height: fit-content;
  }
}
@media (min-width: 768px) {
  .b-icon {
    margin-right: 5px;
  }
}
</style>
