<template>
  <b-modal class="d-flex justify-content-evenly" id="share-modal" hide-footer>
    <template #modal-header="{ close }">
      <h5>{{ $t(`global.share_via`) }}</h5>
      <b-button variant="light" size="sm" @click="close()">
        X
      </b-button>
    </template>
    <div class="share-buttons">
        <ShareNetwork
        network="facebook"
        :url="shareUrl"
        :description="shareDescription"
        hashtags="AgSAT">
        <b-icon icon="facebook" font-scale="4"></b-icon>
        </ShareNetwork>
        <ShareNetwork
        network="LinkedIn"
        :url="shareUrl"
        :description="shareDescription"
        hashtags="AgSAT">
        <b-icon icon="linkedin" class="ml-1" font-scale="4"></b-icon>
        </ShareNetwork>
        <ShareNetwork
        network="WhatsApp"
        :url="shareUrl"
        :description="shareDescription"
        hashtags="AgSAT">
        <b-icon icon="whatsapp" class="ml-1" variant="success" font-scale="4"></b-icon>
        </ShareNetwork>
        <ShareNetwork
        network="twitter"
        :url="shareUrl"
        title="AgSAT"
        :description="shareDescription">
        <b-icon icon="twitter" class="ml-1" font-scale="4"></b-icon>
        </ShareNetwork>
    </div>
  </b-modal>
</template>

<script>

export default {
  name: "SocialMediaShareModal",

  components: {
    
  },

  data () {
    return {
      shareDescription: "Get your daily irrigation values needed for your fields through the smart irrigation AgSAT app Available on agsat.app app store, and on google play.",
      shareUrl: "https://play.google.com/store/apps/details?id=app.crop.smartirrigation",
      windowWidth: window.innerWidth
    }
  },

  computed: {
    mobileViewport () {
      return this.windowWidth < 768;
    }
  },

  mounted () {
    if (this.mobileViewport) {
      this.shareDescription =  "Get your daily irrigation values needed for your fields through the smart irrigation AgSAT app Available on agsat.app and on AppStore",
      this.shareUrl =  "https://apps.apple.com/lb/app/agsat/id1571547783"
    }
  }
};
</script>
<style lang="scss" scoped>
.share-buttons {
    display: flex;
    justify-content: space-between;
}
#share-modal___BV_modal_outer_ {
  #share-modal {
    .modal.show .modal-dialog {
      background-color: red;
    }
  }
};
</style>
