<template>
  <div class="fixed-bottom h-auto results-window" :class="{ 'd-none': hideCard }" style="z-index: 1000">
    <b-card no-body>
      <b-tabs card content-class="mt-3">
        <b-tab :title="$t('results.irrigation_amount')" active>
          <b-spinner v-if="!(result && result.length)" variant="primary" label="Spinning"></b-spinner>
          <div v-for="(dayResult, index) in dates" :key="index">
            {{ $d(new Date(dayResult), 'short') }}:  {{ irrigation[index] }} {{ $t(`global.meter_cube_per_hectare` )}}
          </div>
        </b-tab>
        <b-tab :title="$t('results.irrigation_duration')">
          <b-spinner v-if="!(result && result.length)" variant="primary" label="Spinning"></b-spinner>
          <div v-for="(dayResult, index) in dates" :key="index">
            {{ $d(new Date(dayResult), 'short') }}:  {{ irrigationDuration[index] }} {{ $t(`global.hr_per_day` )}}
          </div>
        </b-tab>

        <b-tab :title="$t('results.history_and_forecast')" class="m-0 p-0">
          <b-spinner v-if="!(result && result.length)" variant="primary" label="Spinning"></b-spinner>
          <div class="min-vw-100" style="direction: ltr;">
            <apexchart ref="chart" width="95%" height="200" :options="options" :series="series" ></apexchart>
          </div>
        </b-tab>

        <b-tab :title="$t('results.mean_fov')" class="m-0 p-0">
          <b-spinner v-if="!(result && result.length)" variant="primary" label="Spinning"></b-spinner>
          <div class="min-vw-100" style="direction: ltr;">
            <apexchart ref="fovChart" width="95%" height="200" :options="fovOptions" :series="fovSeries" ></apexchart>
          </div>
        </b-tab>
        <template #tabs-end>
          <b-nav-item :class="$i18n.locale === 'ar' ? 'mr-auto' : 'ml-auto'" role="presentation" @click.prevent="hide()">
            <b><b-icon :icon="mobileViewPort ? 'chevron-down' : 'x'"></b-icon></b>
          </b-nav-item>
        </template>
      </b-tabs>
      <template>
        <div class="d-block d-sm-none" @click="displayResults">
          <div class="d-flex justify-content-center">
            <b-icon icon="chevron-down" aria-hidden="true"></b-icon>
          </div>
        </div>
      </template>
    </b-card>
  </div>
</template>

<script>
import apexchart from 'vue-apexcharts';
import ApexCharts from 'apexcharts';
import html2canvas from 'html2canvas';
import { mapState, mapGetters } from 'vuex';

export default {
  name: 'ResultsTab',

  props: {
    mobileViewPort: { type: Boolean, default: false },
    field: { type: Object }
  },

  data () {
    return {
      series:  [{
          name: 'series-1',
          data: []
        }],
        fovSeries: [{
          name: 'series-2',
          data: []
        }],
      hideCard: true
    }
  },

  watch: {
    result(newVal, oldVal) {
      if (newVal !== oldVal) {
        // The results have changed. You can update your chart here.
        this.updateChart();
        this.updateFovChart();
      }

      if (newVal === null) {
        this.hide();
      }
    },
    fetchingResults(newVal) {
      if (newVal === true) {
        this.show();
      }
    },
    shouldIgnoreResult(newVal) {
      if (newVal === true) {
        this.hide();
      }
    }
  },

  components: {
    apexchart,
  },

  computed: {
    ...mapState(['fetchingResults', 'result', 'shouldIgnoreResult']),
    ...mapGetters(['getIrrigationRatio', 'getPipeSizeRatio']),

    options () {
      return { chart: {
        toolbar: {
            // show: !(this.mobileViewPort),
          tools: {
            customIcons: [{
              icon: '<b-icon icon="chevron-down" style="color: red" aria-hidden="true"></b-icon>',
              index: 4,
              title: 'tooltip of the icon',
              class: 'custom-icon',
              click: () => {
                this.$nextTick(() => {
                  this.handleDownloadSVG();
                });
              }
            }]
          },
          export: { svg: {filename: `chart-${new Date().toISOString().slice(0, 10)}`} },
        },
        id: 'irrigation-values' },
        xaxis: { categories: [] },
        yaxis: { title: { text: this.$i18n.t('global.meter_cube_per_hectare') } } }
    },

    yAxisConfig() {
      let data = this.meanFov; // replace with your actual data array
      let min = Math.min(...data);
      let max = Math.max(...data);

      return {
        title: { text: this.$i18n.t('global.percent') },
        min: min,
        max: max,
        tickAmount: 5
      };
    },

    fovOptions () {
      return { chart: {
        toolbar: {
          tools: {
            customIcons: [{
              icon: '<b-icon icon="chevron-down" style="color: red" aria-hidden="true"></b-icon>',
              index: 4,
              title: 'tooltip of the icon',
              class: 'custom-icon',
              click: () => {
                this.$nextTick(() => {
                  this.handleDownloadSVG();
                });
              }
            }]
          },
          export: { svg: {filename: `chart-fov-${new Date().toISOString().slice(0, 10)}`} },
        },
        id: 'fov-values' },
        xaxis: { categories: this.dates },
        yaxis: this.yAxisConfig }
    },

    dates() {
      if (this.result) {
        return this.result.slice(-8).map(value => value[1]);
      } else {
        return [];
      }
    },
    irrigation() {
      if (this.result) {
        return this.result.slice(-8).map(value => {
          if (value[0] == null) {
            value[0] = 0;
          } else {
            value[0] = parseFloat(value[0].toFixed(3)*10.0)/this.getIrrigationRatio;
          }
          return value[0].toFixed(3);
        });
      } else {
        return [];
      }
    },
    meanFov () {
      if (this.result) {
        return this.result.slice(-8).map(value => {
          if (value[2] == null) {
            value[2] = 0;
          } else {
            value[2] = (value[2].toFixed(3)*100.0);
          }
          return value[2].toFixed(3);
        });
      } else {
        return [];
      }
    },

    irrigationDuration () {
      if (this.result) {
        return this.result.slice(-8).map(value => {
          if (value[0] == null) {
            value[0] = 0;
          } else {
            value[0] = parseFloat(value[0].toFixed(3)*10.0)/this.getIrrigationRatio;
            let totalRequirement = (this.field.fieldArea/100000) * value[0];
            value[0] = (totalRequirement/this.getPipeSizeRatio).toFixed(2);
          }
          return value[0];
        });
      } else {
        return [];
      }
    }
  },

  methods: {
    hide () {
      this.hideCard = true;
    },

    show () {
      this.hideCard = false;
    },

    updateFovChart() {
      this.$refs.fovChart.updateSeries([{
        name: this.$t('result.irrigation_amount'),
        data: this.meanFov
      }]);
      this.$refs.fovChart.updateOptions({
        xaxis: {
          categories: this.dates
        }
      });
    },

    updateChart() {
      this.$refs.chart.updateSeries([{
        name: this.$t('result.irrigation_amount'),
        data: this.irrigation
      }]);
      this.$refs.chart.updateOptions({
        xaxis: {
          categories: this.dates
        }
      });
    },

    handleDownloadSVG() {
      const chartOptions = this.$refs.chart.options;
       new ApexCharts(this.$refs.chart.$el, chartOptions);
      // ctx.exports.exportToSVG(ctx);
      const chartSvg = document.querySelector('#irrigation-values svg');
      const canvas = html2canvas(chartSvg);
      const imgData = canvas.toDataURL('image/png');
      const link = document.createElement('a');
      link.href = imgData;
      console.log(link)
      link.download = `chart-${new Date().toISOString().slice(0, 10)}.png`;
      link.click();
    },

    swipeHandler (direction) {
      if (direction == 'bottom') {
        document.getElementsByClassName('tabs')[0].style.display = 'none';
      }
    },

    displayResults() {
      document.getElementsByClassName('tabs')[0].style.display = 'block';
    }
  }
};
</script>

<style lang="scss" scoped>
.mr-auto {
  margin-right: auto;
}

@media (max-width: 576px) {
  .card-header {
    padding-top: 0.4rem;
    padding-bottom: 0.4rem;
  }
}
</style>
