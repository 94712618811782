<template>
  <div class="irrigation-models">
    <b-modal style="min-height: 15rem" @ok="showIrrigationSystemModal"  @cancel="clearField" scrollable id="crop-type-modal" data-step="4">
      <b-input-group>
        <input class="form-control form-check-input ml-0" v-model="searchTerm" type="text" style="border: 1px solid #CCCCCC; border-radius: 0.25rem 0 0 0.25rem; width: 80%; min-height: 2rem">
        <b-input-group-append>
          <b-input-group-text style="margin-top: 0.3rem">
            <b-icon icon="x" @click="searchTerm = ''"/>
          </b-input-group-text>
        </b-input-group-append>
      </b-input-group>

      <div class="my-4 form-check" v-for="(cropType, index) in filteredItems" :key="index">
        <input class="form-check-input" v-model="selectedCrop" type="radio" :name="cropType.name" :id="cropType.id" :value="cropType.name">
        <label class="form-check-label" :name="cropType.name" :for="cropType.name">
          {{ cropType.name }}
        </label>
      </div>
      <template #modal-header="{ close }">
        <h5>{{ $t(`crops.crops_title`) }}</h5>
        <b-button ref="Close" variant="light" size="sm" @click="close()">
          X
        </b-button>
      </template>

      <template  #modal-footer="{ ok }">
        <b-button size="md" @click="clearField()">{{ $t(`global.cancel`) }}</b-button>
        <b-button :disabled="!selectedCrop" size="md" variant="primary" @click="ok()">{{ $t(`global.next`) }}</b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>

export default {
  name: "CropTypeModal",
  data () {
    return {
      searchTerm: '',
      selectedCrop: '',
      disabledSave: true,
    }
  },

  computed: {
    cropTypeOptions () {
      return Object.entries(this.$t(`crops.types`)).map(([index, cropName]) => ({ id: index, name: cropName }));
    },

    translatedItems() {
      // Access translated items using this.$t
      return this.$t('crops.types');
    },

    filteredItems() {
      // Use the computed property to filter items based on searchTerm
      const regex = new RegExp(this.searchTerm, 'i'); // 'i' for case-insensitive search
      return this.cropTypeOptions.filter(item => regex.test(item.name));
    }
  },

  methods: {
    enable (e) {
      this.selectedCrop = e.name;
      if (this.selectedCrop && !this.selectedCrop == '') {
        this.disabledSave = false;
      } else {
        this.disabledSave = true;
      }
    },

    showIrrigationSystemModal () {
      if (this.selectedCrop) {
        this.$store.commit('setDraftCrop', this.selectedCrop);
        this.$emit('showIrrigationModal');
      }
    },

    onKeyDown(event) {
      if (event.key === 'Escape') {
        this.$refs.Close.click();
        this.$emit('clearDraftSelection');
      }
    },

    clearField () {
      // this.$emit('endDrawing');
      this.$refs.Close.click();
      this.$emit('clearDraftSelection');
    }
  }
}
</script>
