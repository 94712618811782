<template>
  <b-modal scrollable id="contact-us" @ok="submitForm" :ok-title="$t(`contact_us.send`)" :cancel-title="$t(`contact_us.cancel`)">
    <form>
      <div class="form-group">
        <label for="recipient-name" class="col-form-label">{{ $t(`contact_us.subject`)}}</label>
        <input type="text" class="form-control" v-model="subject" id="recipient-name">
      </div>
      <div class="form-group">
        <label for="message-text" class="col-form-label">{{ $t(`contact_us.message`)}}</label>
        <textarea class="form-control" v-model="message" id="message-text"></textarea>
      </div>
    </form>
    <template #modal-header=" { close }">
      <h5>{{ $t(`global.contact_us`) }}</h5>
      <b-button variant="light" size="sm" @click="close()">
        X
      </b-button>
    </template>
  </b-modal>
</template>
<script>
export default {
  name: 'ContactUsModal',
  props: {
    user: {
      type: Object
    }
  },
  data() {
    return {
      subject: '',
      message: ''
    }
  },
  methods: {
    submitForm() {
      let vmInstance = this;
      fetch('https://script.google.com/macros/s/AKfycbzgA-ewvrSCIIHoU0HuPMIYlpYixuVkAhFVgQZt-hITMPENLkRBujZL6SwlCvLu0TA1xg/exec', {
        method: 'POST',
        mode: 'no-cors',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          user: this.user.email,
          subject: this.subject,
          message: this.message
        })
      })
      .then(response => response.json())
      .then(data => {
        if (data.result === 'success') {
          // Handle success
          vmInstance.subject = '';
          vmInstance.message = '';
        } else {
          // Handle error
        }
      })
      .catch((error) => {
        console.error('Error:', error);
      });
      vmInstance.subject = '';
      vmInstance.message = '';
    }


  }
}
</script>
