<template>
	<b-modal centered id="irrigation-duration-alert" >
		<p class="my-4">{{ $t(`global.irrigation_exceeded` )}}</p>

		<template  #modal-footer="{ ok, cancel }">
			<b-button size="md" @click="cancel()">{{ $t(`global.cancel`) }}</b-button>
			<b-button size="md" variant="primary" @click="ok()">{{ $t(`global.ok`) }}</b-button>
		</template>
	</b-modal>
</template>
<script>
export default {
  name: 'IrrigationTimeExceededModal'
}
</script>
