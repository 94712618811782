<template>
  <div>
    <b-modal centered id="field-area" :title="$t(`global.field_area`)">
      <template #modal-header="{ close }">
        <h5>{{ $t(`global.field_area`) }}</h5>
        <b-button size="sm" variant="light" @click="close()">
          X
        </b-button>
      </template>
      <p class="my-4">Field Area: {{ fieldArea }} square meters</p>

      <template #modal-footer="{ ok, cancel }">
        <b-button size="md" @click="cancel()">{{ $t(`global.cancel`)}}</b-button>
        <b-button size="md" variant="primary" @click="ok()">{{ $t(`global.ok`)}}</b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>

  export default {
    name: "FieldAreaModal",
    props: {
      fieldArea: {
        type: Number
      }
    },
  }
</script>
