// import * as firebase from '../firebase'
// import { auth } from '../firebase'
// import fieldStore from './FieldStore';
// Vue.use(Vuex);
// realtime firebase

// const userStore = new Vuex.Store({
//   state: {
//     users: [],
//     user: {
//       loggedIn: false,
//       data: null
//     }
//   },

//   getters: {
//     user(state){
//       return state.user
//     }
//   },

//   mutations: {
//     setUsers(state, val) {
//       state.users = val
//     },

//     setLoggedIn(state, value) {
//       state.user.loggedIn = value;
//     },

//     setUser(state, data) {
//       state.user.data = data;
//     }
//   },

//   actions: {
//     async fetchUsers({ commit }) {
//       firebase.usersCollection.on('value', function(snapshot) {
//         let usersArray = [];
//         snapshot.forEach(function(childSnapshot) {
//           usersArray.push(childSnapshot.val());
//           // Fill the local data property with Firebase data
//           commit('setUsers', usersArray);
//         });
//       });
//     },

//     fetchUser({ commit }, user) {
//       commit("setLoggedIn", user !== null);
//       if (user) {
//         commit("setUser", {
//           displayName: user.displayName,
//           email: user.email
//         });
//       } else {
//         commit("setUser", null);
//       }
//     }
//   }
// })


// class UserStore {
  // constructor () {
  //   this.nullifyUser();
  // }

  // nullifyUser () {
  //   this.user = null;
  // }

  // ensureCurrentUser () {
  //   this.user;
  // }
// }
import Vuex from 'vuex';
import Vue from 'vue';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    user: null,
    fields: null
  },
  mutations: {
    setUser(state, user) {
      state.user = user;
    },
    setFields(state, fields) {
      state.fields = fields;
    },
  },
  actions: {
    // async fetchFieldsByDevice({ commit }, email) {
      // const fields = await fieldStore.fetchFieldsByDevice(email);
      // console.log(fields)
      // commit('setFields', fields);
    // },

    // async login({ commit }, user) {
    //   commit('setUser', user);
    //   const fields = await fieldStore.fetchFieldsByDevice(user.email);
    //   console.log(fields)
    //   commit('setFields', fields);
    // },
  },
});
