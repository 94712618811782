<template>
  <b-modal centered id="custom-setting-modal">
    <div class="custom-setting-page">
     <b-form inline class="d-flex justify-content-between mt-2">
      <label for="input-language" class="ml-3">{{$t(`global.language`)}}</label>
        <b-form-select
          class="w-50"
          id="input-language"
          v-model="language"
          :options="lang"
          required>
        </b-form-select>
     </b-form>
    </div>
    <template #modal-footer="{ save, cancel }">
      <b-button size="md" @click="cancel()">
        {{ $t('global.cancel') }}
      </b-button>
      <b-button size="md" variant="primary" @click="formSubmitted()">
        {{ $t('global.save') }}
      </b-button>
    </template>
  </b-modal>
</template>

<script>
import settingsStore from '../../stores/SettingsStore';
import { Plugins } from "@capacitor/core";
const { Device } = Plugins;


export default {
  name: 'CustomSettings',

  data () {
    return {
      windowWidth: window.innerWidth,
      deviceId: null,
      deviceInfo: null,
      language : null,
      lang:
        [
          { value: 'en', text: this.$i18n.t(`global.english`) },
          { value: 'ar', text: this.$i18n.t(`global.arabic`) },
        ]
    }
  },

  computed: {
    mobileViewport () {
      return this.windowWidth < 768;
    }
  },

  methods: {
    async getDeviceInfo() {
      this.deviceInfo = await Device.getInfo();
      this.deviceId = this.deviceInfo.uuid;
    },

    async getLanguage() {
      this.language = await settingsStore.fetchLanguageByDeviceId(this.deviceId)
      if (!this.language) {
        this.language = 'en';
        localStorage.language = this.language;
      }
    },

    async formSubmitted() {
      settingsStore.saveLanguageByDeviceId(this.deviceId, this.language);
      this.$root.$emit('changeLanguage', this.language);
      this.$root.$emit('bv::hide::modal', 'custom-setting-modal');
    },
  },
  watch: {
    deviceId: function() {
      this.getLanguage();
    }
  },

  created () {
    this.getDeviceInfo()
  }
};
</script>

