<template>
	<div class="main top-notch" id="agsat-app" style="overflow: hidden; display: flex; flex-direction: column;">
		<!-- TOP HEADER THAT INCLUDES LOGO, SEARCH, AND SETTINGS BUTTON -->
		<Header :a="this.$refs.mapView" :user='user' :mobile-view-port="mobileViewPort"/>

		<b-button-toolbar class="d-flex justify-content-md-start">
      <GmailLogin  :mobile-view-port="mobileViewPort" @clearMap="clearMap"/>
			<!-- BUTTONS TOOLBAR THAT ARE USED TO START DRAWING YOUR FIELD, EDIT AND DELETE FIELD -->
			<ButtonsToolbar @callMethodInFieldEdit="handleMethodCall" ref="buttonToolbar" @showLoginModal="showLoginModal" @closeInfoWindow="closeInfoWindow" @startDrawing="startDrawing" :field="activeField" :mobile-view-port="mobileViewPort" />
			<!-- CONTROL PANEL THAT IS DISPLAYED ON MAP UNDER THE HEADER IT INCLUDES REALISATION, GUIDE AND LOGIN BUTTON -->
      <ControlPanel :user='user' :map="map" @goToLocation="goToLocation" @goToDetectedLocation="goToDetectedLocation" :mobile-view-port="mobileViewPort" @clearMap="clearMap" @measureFieldArea="measureFieldArea"/>
      <!-- BUTTONS USED FOR DETECT LOCATION, ZOOM IN AND ZOOM OUT ON MOBILE -->
			<MobileZoomControlButtons />
		</b-button-toolbar>

    <svg id="IamANSVG" data-v-73a928ee="" height="400" width="300" style="margin: auto">
      <rect data-step="5" data-v-73a928ee="" data-intro="Hello!" x="118" y="218" width="200" height="200" style="fill: none;"></rect>
    </svg>

    <!-- MAP VIEW -->
		<MapView @map-loaded="handleMapLoaded" :mobile-view-port="mobileViewPort" ref="mapView" @showLoginModal="showLoginModal" @fetchFieldIrrigation="fetchAndDisplayFieldIrrigation"/>

		<!-- FIELDINDEX CONTAINS A LIST OF ALL FIELDS FETCHED FOR THE CURRENT USER -->
		<FieldIndex :mobile-view-port="mobileViewPort" :current-fields="fields" @delete-field="handleDeleteField" @goToField="handleGoToField"/>
  <!-- FIELDNEW CONTAINS THE MODAL FOR A NEW FIELD -->
		<FieldNew :field="draftField" :fields="fields" />
		<!-- FIELDEDIT IS THE SAME AS THE FIELDNEW BUT POPULATED WITH THE SELECTED FIELD DETAILS -->
		<FieldEdit v-if="activeField" @goToField="handleGoToField" @closeInfoWindow="closeInfoWindow" ref="fieldEdit" :field="activeField"/>
		<!-- SOCIAL MEDIA SHARE MODAL -->
		<SocialMediaShareModal />

		<!-- CROP TYPE MODAL USED BY THE USER TO SELECT THE CROP TYPE FOR HIS FIELD-->
		<CropTypeModal @clearDraftSelection="clearDraftSelection" @showIrrigationModal="showIrrigationModal" />
    <!-- IRRIGATION SYSTEM MODAL USED BY THE USER TO SELECT THE IRRIGATION SYSTEM USED FOR HIS FIELD-->
		<IrrigationSystemModal @clearDraftSelection="clearDraftSelection" @showPipeSizeModal="showPipeSizeModal"/>
		<!-- PIPE SIZE  MODAL USED BY THE USER TO SELECT THE PIPE SIZE USED FOR HIS FIELD-->
    <PipeSizeModal :mobile-view-port="mobileViewPort" @clearDraftSelection="clearDraftSelection" @startDrawing="startDrawing" />
		<!--MODAL CALLED WHEN IRRIFATION SYSTEM EXCEEDS 24 HOURS -->
		<IrrigationTimeExceededModal/>
    <LoginRequiredModal />
    <FieldAreaModal :fieldArea="area"/>

		<!--SIDEBAR WHICH INCLUDES ALL FIELDS DISPLAYED ONLY FOR ADMINS -->
		<!-- <AllUsersSidebar :fields="fields" v-on:childToParent="filterFieldByUserSelection" /> -->
		<!-- DETAILED FIELD INFO FOR THE SELECTED FIELD -->
		<!-- <SidebarInfo :selected-field="activeField" /> -->

		<!-- RESULTS TAB AND HISTORY CHART -->
    <ResultsTab :mobile-view-port="mobileViewPort" :field="activeField || draftField"/>
		<!-- <ResultsTab :results-object="res"
			:hide-tabs="hideTabs"
			:fetching-data="fetchingData"
			:series="series"
			:options="options"
			@fetchDataChanged="(e) => { stopFetchingData = e }"
			@hideTabsChanged="(e) => { hideTabs = e }"
    /> -->

		<!-- THE HELP TEXT DIV THAT SHOWS ON MOBILE WHEN USER IS NOT LOGGED IN AND DOESNT HAVE ANY SAVED FIELDS -->
		<!-- <HelpText /> -->
	</div>
</template>
<script>
import Header from './Header';
import ControlPanel from './ControlPanel';
import ButtonsToolbar from './ButtonsToolbar';
import MobileZoomControlButtons from './MobileZoomControlButtons';
import FieldIndex from '../Field/FieldIndex';
import FieldNew from '../Field/FieldNew';
import FieldEdit from '../Field/FieldEdit';
import SocialMediaShareModal from './SocialMediaShareModal';
import MapView from './MapView';
import GmailLogin from './GmailLogin';
import CropTypeModal from './CropTypeModal';
import LoginRequiredModal from './LoginRequiredModal';
import IrrigationSystemModal from './IrrigationSystemModal';
import PipeSizeModal from './PipeSizeModal';
import IrrigationTimeExceededModal from './IrrigationTimeExceededModal';
import FieldAreaModal from './FieldAreaModal';
import * as firebase from '../../firebase';
// import AllUsersSidebar from './AllUsersSidebar';
// import SidebarInfo from './SidebarInfo';
import ResultsTab from './ResultsTab';
// import HelpText from './HelpText';
import { mapState, mapGetters } from 'vuex';
import { getPolygonValue  } from '../../services/UserService';



export default {
	name: 'Dashboard',

	components: {
		Header,
		ControlPanel,
		ButtonsToolbar,
		MobileZoomControlButtons,
		FieldIndex,
		FieldNew,
		FieldEdit,
		SocialMediaShareModal,
		MapView,
    GmailLogin,
    LoginRequiredModal,
		CropTypeModal,
		IrrigationSystemModal,
		PipeSizeModal,
		IrrigationTimeExceededModal,
		ResultsTab,
    FieldAreaModal
	},

	data() {
		return {
      windowWidth: window.innerWidth,
      map: null,
      area: 0,
      pipeSizeRatio: null,
      pipeSizes:[
				"32 mm, 1 inch",
				"40 mm, 1.25 inch",
				"50 mm, 1.5 inch",
				"63 mm, 2 inch",
				"75 mm, 2.5 inch",
				"90 mm, 3 inch",
				"110 mm, 4 inch",
				"140 mm, 5 inch",
				"160 mm, 6 inch"
			],
      irrigationSystemTypes: [
        'Drip',
        'Bubbler',
        'Microsprinkler',
        'Solid-set impact sprinkler',
        'Center Pivot',
        'Linear move',
        'Hand-move sprinkler',
        'Boom sprayer',
        'Gun',
        'Surface-Basin',
        'Surface-Furrow'
      ],
      irrigationRatio: 1,
      currentPolygon: null
		}
	},

  created () {
    this.goToDetectedLocation();
    // eslint-disable-next-line
    const user = localStorage.getItem('user');
    if (user) {
      this.$store.dispatch('login', JSON.parse(user));
    }
    firebase.firebase.auth().onAuthStateChanged(user => {
      if (user) {
        this.$store.dispatch('login', user);
        this.$store.dispatch('fetchFieldsByDevice', user.email || user.phoneNumber);
      } else {
        this.user = null;
      }
    });

    this.$root.$on('goToField', this.handleGoToField);
  },

  mounted () {
    this.callApiEveryFiveMinutes();
    this.initializeDrawingManagerTool();
    window.addEventListener('resize', this.handleResize);
    window.addEventListener('keydown', this.onKeydown);
  },



  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize);
    window.removeEventListener('keydown', this.onKeydown);
  },

	methods: {
    handleMapLoaded(map) {
      this.map = map;
    },

    callApiEveryFiveMinutes() {
      setInterval(() => {
        fetch('https://gee-oimpdqmkkq-uc.a.run.app/api/users?type=Polygon&coordinates=35.49954900349101,33.884483571496155,35.5003214796873,33.884537011595036,35.500450225720016,33.88376212688474,35.49966702068767,33.88364633890985,35.49954900349101,33.884483571496155')
          .then(response => response.json())
          .then(data => console.log(data))
          .catch(error => console.error('Error:', error));
      }, 300000); // every 5 minutes
    },

    initializeDrawingManagerTool () {
      var self = this;
      this.$refs.mapView.$refs.googleMap.$mapPromise.then((map) => {
        window.google.maps.event.addListener(map, 'contextmenu', function(event) {
          event.preventDefault();
        });
        const input = document.getElementById("place-search");
        new window.google.maps.places.Autocomplete(input);

        let googleMap = window.google.maps.drawing;
        const drawingManager = new googleMap.DrawingManager({
          drawingMode: null,
          drawingControl: true,
          drawingControlOptions: {
            position: window.google.maps.ControlPosition.LEFT_BOTTOM,
            drawingModes: [
              googleMap.OverlayType.POLYGON
            ],
          },
          markerOptions: {
            icon:
              "https://developers.google.com/maps/documentation/javascript/examples/full/images/beachflag.png",
          },
          polygonOptions: {
            strokeColor: "#8B4513",
            fillColor: "#98FB98",
            editable: true,
          },
          circleOptions: {
            strokeColor: "#FF0000",
            strokeOpacity: 0.8,
            strokeWeight: 2,
            fillColor: "#FF0000",
            fillOpacity: 0.35,
            clickable: false,
            editable: true,
            zIndex: 1,
          },
        });
        drawingManager.setMap(map);
        self.drawingManager = drawingManager;
      });
    },

    async goToDetectedLocation () {
      navigator.geolocation.getCurrentPosition(function(position) {
        const lat = position.coords.latitude;
        const lng = position.coords.longitude;
        this.goToLocation(lat, lng);
        this.addCurrentLocationMarker(lat, lng);
      }.bind(this));
    },

    handleGoToField (fieldInfo) {
      this.goToLocation(fieldInfo.markerLatitude, fieldInfo.markerLongitude, 18);
    },

    handleMethodCall() {
      this.$refs.fieldEdit.editFieldDrawing();
    },

    addCurrentLocationMarker(latitude, longitude) {
      this.$refs.mapView.$refs.googleMap.$mapPromise.then((map) => {
        // Create a new marker
        const marker = new window.google.maps.Marker({
          position: { lat: latitude, lng: longitude },
          map: map
        });

        // Create a new info window
        const infoWindow = new window.google.maps.InfoWindow({
          content: '<b>Current Location</b>'
        });

        infoWindow.open(map, marker);

        // Add a click event listener to the marker to open the info window
        marker.addListener('click', function() {
          infoWindow.open(map, marker);
        });
      });
    },

    goToLocation (lat=null, lng=null, zoomLevel=17) {
      this.$refs.mapView.$refs.googleMap.$mapPromise.then((map) => {
        if (lat && lng) {
          const position = {lat: lat,lng: lng};
          map.setCenter(position);
          map.setZoom(zoomLevel);
        }
      });
    },

    removeFieldPolygon (polygon) {
      this.$store.commit('removeMapPolygon', polygon);
    },

    closeInfoWindow() {
      this.$refs.mapView.closeInfoWindow();
    },

    onKeydown(event) {
      if (event.key === 'Escape') {
        this.stopDrawing();
      }
    },

    stopDrawing() {
      // code to stop drawing
      document.getElementsByClassName("gmnoprint")[0].children[1].children[0].click();
      document.getElementsByClassName("gmnoprint")[0].children[0].children[0].click();
    },

    startDrawing () {
      let self = this;
      this.currentPolygon = null;
      if (this.mobileViewport) {
        this.$refs.googleMap.$mapPromise.then((map) => {
          self.drawingManager.setMap(map);
        });
      } else {
        document.getElementsByClassName("gmnoprint")[0].children[1].children[0].click();
      }
      // eslint-disable-next-line
      window.google.maps.event.clearListeners(self.drawingManager, 'polygoncomplete');
      // eslint-disable-next-line
      window.google.maps.event.addListener(self.drawingManager, 'polygoncomplete', function(polygon) {
        // This function is called when the user finishes drawing a polygon.
        // The polygon parameter is the polygon that was drawn.
        self.$store.commit('setFetchingResults', true);

        var computedArea = window.google.maps.geometry.spherical.computeArea(polygon.getPath());
        var fieldAttributes = {
          pipeSize: self.$store.state.draftPipeSize,
          irrigationSystem: self.$store.state.draftIrrigationSystem,
          cropType: self.$store.state.draftCrop,
          fieldArea: parseFloat(computedArea).toFixed(2),
          position: (self.formatPolygonCoordinates(self.getPolygonCoordinates(polygon.getPath()))).join(','),
          markerLatitude: self.getPolygonCentroid(polygon).latitude,
          markerLongitude: self.getPolygonCentroid(polygon).longitude,
        }
        self.currentPolygon = polygon;
        self.fetchAndDisplayFieldIrrigation(polygon);
        self.addMarker(polygon);
        polygon.setEditable(false);
        self.$store.commit('setDraftField', fieldAttributes);

        // End the drawing mode
        self.drawingManager.setDrawingMode(null);
      });
    },

    measureFieldArea () {
      let self = this;
      this.currentPolygon = null;
      if (this.mobileViewport) {
        this.$refs.googleMap.$mapPromise.then((map) => {
          self.drawingManager.setMap(map);
        });
      } else {
        document.getElementsByClassName("gmnoprint")[0].children[1].children[0].click();
      }
      // eslint-disable-next-line
      google.maps.event.clearListeners(self.drawingManager, 'polygoncomplete');


      // eslint-disable-next-line
      google.maps.event.addListener(self.drawingManager, 'polygoncomplete', function(polygon) {
        const computedArea = window.google.maps.geometry.spherical.computeArea(polygon.getPath());
        self.area = parseFloat(computedArea).toFixed(2);
        if (self.area > 0) {
          self.$bvModal.show('field-area');
        }

        setTimeout(() => {
          polygon.setMap(null);
        }, 3500); // 30000 milliseconds = 30 seconds

        self.drawingManager.setDrawingMode(null);
      });
    },

    fetchAndDisplayFieldIrrigation(polygon) {
      this.$store.commit('setFetchingResults', true);
      this.getPolygonIrrigationResult(polygon);
      this.$store.commit('setActivePolygon', polygon);
    },

    getPolygonCentroid (polygon) {
      let path = polygon.getPath();
      let lat = 0;
      let lng = 0;
      for (let i = 0; i < path.getLength(); i++) {
        lat += path.getAt(i).lat();
        lng += path.getAt(i).lng();
      }
      lat /= path.getLength();
      lng /= path.getLength();
      return {latitude: lat, longitude: lng};
    },

    addMarker (polygon) {
      let self = this;
      this.$refs.mapView.$refs.googleMap.$mapPromise.then((map) => {
        // Create a new marker
        let centroid = this.getPolygonCentroid(polygon);
        let marker = new window.google.maps.Marker({
          position: { lat: centroid.latitude, lng: centroid.longitude },
          map: map
        });

        self.$store.commit('setActiveMarker', marker);
      });
    },

    getPolygonIrrigationResult (polygon) {
      this.$store.commit('setResults', null);
      let coordinates = this.getPolygonCoordinates(polygon.getPath());
      let geojson = this.getGeoJson(coordinates);

      let self = this;
      let apiCall = getPolygonValue(geojson);
      let timeout = new Promise((resolve, reject) => {
        setTimeout(() => reject(new Error('Request timed out')), 20000); // 20 sec
      });

      Promise.race([apiCall, timeout])
        .then(response => {
          if (self.shouldIgnoreResult) {
            self.$store.commit('setFetchingResults', true);
            return;
          } else {
            self.$store.commit('setFetchingResults', false);
            self.$store.commit('setResults', response);
            let dailyValue = (parseFloat(response[response.length - 5][0]).toFixed(2) * 10.0)/this.getIrrigationRatio;
            let totalRequirement, fieldAreaInHectare;
            let irrigationDuration;
            let field = this.activeField || this.draftField;
            fieldAreaInHectare = (parseFloat(field.fieldArea/10000)).toFixed(2);
            totalRequirement = (fieldAreaInHectare * dailyValue).toFixed(2);
            self.getPipeSizeRatio(field.pipeSize);
            irrigationDuration = (totalRequirement/self.pipeSizeRatio).toFixed(2);
            if (irrigationDuration > 24) {
              this.$bvModal.show('irrigation-duration-alert');
              self.$store.commit('setShouldIgnoreResult', true);
              document.getElementById('clear-selection').click();
            } else {
              let windownResult = self.setInfoWindowContent(dailyValue, fieldAreaInHectare, totalRequirement, irrigationDuration);
              self.$store.commit('setIrrigationResult', windownResult);
            }
          }
        })
        .catch(error => {
          if (error.message === 'Request timed out') {
            let windownResult = 'The request has timed out. Please try again.';
            self.$store.commit('setIrrigationResult', windownResult);
          } else {
            console.log(error);
          }
        });
      // Call the API
      // getPolygonValue(geojson)
      //   .then(response => {
      //     if (self.shouldIgnoreResult){
      //       self.$store.commit('setFetchingResults', true);
      //       return;
      //     } else{
      //       self.$store.commit('setFetchingResults', false);
      //       self.$store.commit('setResults', response);
      //       let dailyValue = (parseFloat(response[response.length - 5][0]).toFixed(2) * 10.0)/this.getIrrigationRatio;
      //       let totalRequirement, fieldAreaInHectare;
      //       let irrigationDuration;
      //       let field = this.activeField || this.draftField;
      //       fieldAreaInHectare = (parseFloat(field.fieldArea/10000)).toFixed(2);
      //       totalRequirement = (fieldAreaInHectare * dailyValue).toFixed(2);
      //       self.getPipeSizeRatio(field.pipeSize);
      //       irrigationDuration = (totalRequirement/self.pipeSizeRatio).toFixed(2);
      //       let windownResult = self.setInfoWindowContent(dailyValue, fieldAreaInHectare, totalRequirement, irrigationDuration);
      //       self.$store.commit('setIrrigationResult', windownResult);
      //     }
      // })
      //   .catch(error => {
      //     console.log(error);
      // });
    },

    setInfoWindowContent: function (value, fieldArea, totalRequirement, irrigationDuration) {
        value = this.$i18n.n(value)
        fieldArea = this.$i18n.n(fieldArea)
      if (totalRequirement) {
        totalRequirement = this.$i18n.n(totalRequirement)
        irrigationDuration = this.$i18n.n(irrigationDuration)
        return (
        `<div class="">
          <div>
            <div>
              <div class="m-2"><span style="font-weight: bold;">${this.$i18n.t(`global.water_requirement_today`)}: </span>
                ${value} ${this.$i18n.t(`global.meter_cube_per_hectare`)}
              </div>
              <div class="m-2"><span style="font-weight: bold;">${this.$i18n.t(`global.field_area_in_hectare`)}: </span>
                ${fieldArea} ${this.$i18n.t(`global.hectare`)}
              </div>
              <div class="m-2"><span style="font-weight: bold;">${this.$i18n.t(`global.total_requirement`)}: </span>
                ${totalRequirement} ${this.$i18n.t(`global.meter_cube_per_day`)}
              </div>
              <div class="m-2"><span style="font-weight: bold;">${this.$i18n.t(`global.irrigation_duration`)}: </span>
                ${irrigationDuration} ${this.$i18n.t(`global.hr_per_day`)}
              </div>
            </div>
          </div>
        </div>`);
      } else {
        return (
        `<div class="">
          <div>
            <div>
              <div class="m-2"><span style="font-weight: bold;">${this.$i18n.t(`global.crop_evapotranspiration`)}: </span>
                ${value} ${this.$i18n.t(`global.mm_per_day`)}
              </div>
            </div>
          </div>
        </div>`);
      }
    },

    getPolygonCoordinates (polygonPath) {
      let coordinates = polygonPath.getArray().map(point => [point.lng(), point.lat()]);

      // Add the first point to the end of the array to close the polygon
      coordinates.push(coordinates[0]);
      return coordinates;
    },

    getGeoJson (coordinates) {
      // Create geojson object
      return {
        "type": "Polygon",
        "coordinates": [coordinates]
      };
    },

    formatPolygonCoordinates (coordinates) {
      return coordinates.map(coords => `lat/lng: (${coords[1]}, ${coords[0]})`);
    },

    handleDeleteField(fieldId) {
      this.$refs.buttonToolbar.deleteField(fieldId);
    },

    clearMap () {
      // Loop over polygons
      this.mapPolygons.forEach(polygon => {
        // You can access each polygon here
        polygon.setMap(null);
      });

      // Loop over markers
      this.allMarkers.forEach(marker => {
        // You can access each marker here
        marker.setMap(null);
      });
    },


    getPipeSizeRatio (pipeSize) {
      switch(this.pipeSizes.indexOf(pipeSize)) {
        case 0:
          this.pipeSizeRatio = 2.6;
          break;
        case 1:
          this.pipeSizeRatio = 4.0;
          break;
        case 2:
          this.pipeSizeRatio = 6.0;
          break;
        case 3:
          this.pipeSizeRatio = 11.0;
          break;
        case 4:
          this.pipeSizeRatio = 17.0;
          break;
        case 5:
          this.pipeSizeRatio = 24.0;
          break;
        case 6:
          this.pipeSizeRatio = 42.0;
          break;
        case 7:
          this.pipeSizeRatio = 66.0;
          break;
        case 8:
          this.pipeSizeRatio = 90.0;
          break;
      }
    },

    getIrrigationRatio () {
      switch(this.irrigationSystemTypes.indexOf(this.selectedIrrigationSystemType)) {
        case 0:
          this.irrigationRatio = 0.85;
          break;
        case 1:
          this.irrigationRatio = 0.8;
          break;
        case 2:
          this.irrigationRatio = 0.8;
          break;
        case 3:
          this.irrigationRatio = 0.75;
          break;
        case 4:
          this.irrigationRatio = 0.85;
          break;
        case 5:
          this.irrigationRatio = 0.8;
          break;
        case 6:
          this.irrigationRatio = 0.75;
          break;
        case 7:
          this.irrigationRatio = 0.75;
          break;
        case 8:
          this.irrigationRatio = 0.7;
          break;
        case 9:
              this.irrigationRatio = 0.6;
              break;
        case 10:
              this.irrigationRatio = 0.5;
              break;
      }
    },

    showIrrigationModal () {
      this.$bvModal.show('irrigation-system-modal');
    },

    showPipeSizeModal() {
      this.$bvModal.show('pipe-size-modal');
    },

    showLoginModal () {
      if (!this.userGuideMode) {
        this.$bvModal.show('login-required-modal');
      }
    },

    clearDraftSelection () {
      this.$store.dispatch('clearDraftSelection');
    },
    handleResize() {
      this.windowWidth = window.innerWidth;
    },
	},

	computed: {
		...mapState(['user', 'fields', 'activeField', 'mapPolygons', 'allMarkers', 'activeMarker', 'shouldIgnoreResult', 'draftField', 'irrigationWindow', 'userGuideMode']),
    ...mapGetters(['getIrrigationRatio']),
		mobileViewPort () {
			return this.windowWidth < 768;
		}
	}
}
</script>
